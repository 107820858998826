import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import classess from "../../../../src/ComponentsV2/styles/createjobv2.module.css"

import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import {
  COUNTRIES,
  COMPANY_TYPES,
  COUNTRY_INSURANCES,
  REGIONS_COUNTRIES,
} from '../../../Types/Constants';
import {
  getCountryByAbbr,
  getLabelForRegistrationNumberByCountry,
} from '../../../Utilities/Country';
import { isNumeric } from '../../../Utilities/Regex';
import { Company } from '../../../Api/Company/Company';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from '../../Common/MapAutocomplete/MapAutocomplete';
import MapAddress from '../../../BP/MapAddress';
import TermsOfServiceDialog from '../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialog';
import CustomDatePicker from '../../../ComponentsV2/ui/CustomDatePicker';
import FileInput from '../../Common/FileInput/FileInput';
import { MdDelete } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { Formik } from 'formik';
import * as Yup from "yup";
import { validationCompanyRegister } from '../../../ComponentsV2/lib/validationSchema';
import { FormHelperText } from '@material-ui/core';
import { checkIsEmpty } from '../../../ComponentsV2/lib/helper';
import { s3UploadMethod } from '../../../Utilities/awsFileUploder/awsFileUploder';
import SelectorComp from '../../../ComponentsV2/ui/SelectorComp';
import { useDropzone } from 'react-dropzone/.';
import AddIcon from '@material-ui/icons/Add';
import { DOCS } from '../../../ComponentsV2/lib/constants';
import TermsAndConditions from '../../../Utilities/TermsAndConditions';
const {
  FREIGHT_FORWARED,
  NVOCC,
  IMPORT_EXPORTER,
  SHIPPING_LINE,
  TRANSPORT_COMPANY,
} = COMPANY_TYPES;

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const useStyles = makeStyles((theme) => ({
  companyRegisterBox: {
    backgroundColor: 'white',
    padding: '40px 15px',
    borderRadius: '20px',
    boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)',
    overflowY: 'auto',
    position: 'absolute',
    top: '50px',
    left: '100px',
    right: '100px',
    bottom: '50px',
  },
  logo: {
    width: '150px',
    height: '150px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formControl: {
    minWidth: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
  gap: {
    marginTop: theme.spacing(3),
  },
  btnRegister: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  btnBack: {
    backgroundColor: 'grey',
    color: 'white',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  termsOfServiceBtn: {
    fontStyle: 'italic',
    background: 'none',
    border: 'none',
    padding: '0',
    textDecoration: 'underline',
    color: '#069',
    cursor: 'pointer',
  },
  paragrapHeading: {
    fontWeight: 'normal',
  },
  paragrapHeading2: {
    fontWeight: 'normal',
    fontSize: '12px',
    
  },
  cssFixes: {
    '& .MuiFormHelperText-root': {
      color: 'red !important',
    },
    color: 'red !important',
  },
  cssFixed:{
    '& ..MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-3':{
      padding: "0px",
    }
  },
  sameAsCompany:{
    display:"flex",
    alignItems:"center",
    padding:"0px !important",
  }
}));

const companyApi = new Company();
type DocType = typeof DOCS[number]['value'];



function CompanyRegister() {

  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('CompanyRegister');

  const [showPass, setShowPass] = useState<boolean>(false);
  const [country, setCountry] = useState<string>();
  // const [companyType, setCompanyType] = useState<string>('');
  const [companyName, setCompanyName] = useState('');
  const [companyUen, setCompanyUen] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [confirmMobile, setConfirmMobile] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [streetAndName, setStreetAndName] = useState('');
  const [suburbOrCity, setSuburbOrCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [insurances, setInsurances] = useState<string[]>([]);
  const [accountName, setAccountName] = useState('');
  const [accountPhone, setAccountPhone] = useState('');
  const [accountEmail, setAccountEmail] = useState('');
  const [operationsName, setOperationsName] = useState('');
  const [operationsPhone, setOperationsPhone] = useState('');
  const [operationsEmail, setOperationsEmail] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [isDetailsForOperationsSame, setIsDetailsForOperationsSame] =
    useState(false);
  const [isDetailsForAccountSame, setIsDetailsForAccountSame] = useState(false);
  const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] =
    useState(false);
  const [waitingTime, setWaitingTime] = useState('');
  const [waitingTimeCharge, setWaitingTimeCharge] = useState('');
  const [agreedTos, setAgreedTos] = useState(false);
  const [importerName, setImporterName] = useState('');
  const [importerPhone, setImporterPhone] = useState('');
  const [importerEmail, setImporterEmail] = useState('');
  const [exporterName, setExporterName] = useState('');
  const [exporterPhone, setExporterPhone] = useState('');
  const [exporterEmail, setExporterEmail] = useState('');
  const [latitude, setLatitude] = useState<any>('')
  const [longitude, setLongitude] = useState<any>('')
  const [website, setWebsite] = useState('');
  const COUNTRYAREA = REGIONS_COUNTRIES?.new_south_wales ?? [];
  const [countryRegions, setCountryRegions] = useState<any>([]);
  const [serviceRegionsInternational, setServiceRegionsInternational] =
    useState('');
  const [companyPostalAddress, setCompanyPostalAddress] = useState('');
  const [operationscredit, setCredit] = useState<any>();
  const [error, setError] = useState(false);
  const INSURANCES = COUNTRY_INSURANCES[country] ?? [];
  const [termsCompanySignature, setTermsCompanySignature] =
    useState<any>('');
  const [selectedDate, setSelectedDate] = useState('');
  const [companySheetAttachment, setCompanySheetAttachment] = useState<any>();
  const [consentCompanySignature, setConsentCompanySignature] = useState<any>();
  const [TradingName, setTradingName] = useState('');
  const [Abn_Acn, setAbn_Acn] = useState('');
  const [RegisteredAddress, setRegisteredAddress] = useState('');
  const [Postcode, setPostcode] = useState('');
  const [State, setState] = useState('');
  const [TypeOfBussiness, setTypeOfBussiness] = useState('');
  const [ProductsHandled, setProductsHandled] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [operationscreditlimit, setCreditLimit] = useState<any>();
  const [authorisedName, setAuthorisedName] = useState('');
  const [position, setPosition] = useState('');
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [authorisedTermsName, setAuthorisedTermsName] = useState('');
  const [positionTerms, setPositionTerms] = useState('');
  const [selectedDateTerms, setSelectedDateTerms] = useState('');
  const [city, setCity] = useState("");
  const [hasSearchAddress, setHasSearchAddress] = useState(false)
  const [streetNameAddress, setStreetNameAddress] = useState("");
  const [PostalState, setPostalState] = useState('');
  const [PostalCountry, setPostalCountry] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isCheckedAddress, setIsCheckedAddress] = useState(false);


  const initialValues = {
    companyName: '',
    companyType: '',
    TradingName: '',
    Abn_Acn: '',
    registerAddress: '',
    Postcode: '',
    State: '',
    companyUen: '',
    companyPostalAddress: '',
    mobile: '',
    email: '',
    website: '',
    TypeOfBussiness: '',
    ProductsHandled: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    adminName: '',
    adminPhone: '',
    adminEmail: '',
    authorisedName: '',
    position: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    operationscreditlimit: '',
    operationscredit: '',
    authorisedRepresentativeName: '',
    positionTitle: '',
    selectedOptions: [],

  };


  const [sameCompany, setSameCompany]= useState(false);
  const [sameRegister, setSameRegister] = useState(false);
 const [registerAddress, setRegisterAddress] = useState(initialValues?.registerAddress);

  const [docType, setDocType] = useState<DocType | ''>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileContainer, setFileContainer] = useState<File | null>(null);
  const [docTypeContainer, setDocTypeContainer] = useState<DocType | ''>('');
  const [changeMi4u, setChangeMi4u] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<Record<DocType, File | null>>(
      DOCS.reduce((acc, doc) => {
          acc[doc.value as DocType] = null;
          return acc;
      }, {} as Record<DocType, File | null>)
  );



  const handleTermsOfServiceDialogClose = useCallback(() => {
    setIsTermsOfServiceDialogOpen(false);
  }, []);

  const handleBackButtonClick = () => {
    history.push(routes.landing.login[0]);
  };
  const handleFileUploadCompanyRegister = (e: any) => {
    const filefileUpload = e.target.files[0];
    setCompanySheetAttachment(filefileUpload);
  };




  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
    }
};

const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: '.pdf,.doc,.docx,.jpg,.jpeg,.png',
    maxFiles: 1,
});

const handleAddDocument = () => {
    if (docType && file) {
        setUploadedFiles((prevFiles) => ({
            ...prevFiles,
            [docType]: file,
        }));
        setDocType('');
        setFile(null);
    } else {
        toast.error('Please select a document type and upload a file.');
    }
};
const handleDeleteFile = (type: DocType) => {
    setUploadedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
    }));
};

const handleDocTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setDocType(event.target.value as DocType);
};


function getNonNullValues(data) {
  // Initialize an empty array to store non-null values
  const nonNullValues = [];

  // Loop through each key in the object
  for (const key in data) {
      if (data[key] !== null) {
          // Add the value to the array if it's not null
          nonNullValues.push(data[key]);
      }
  }

  return nonNullValues;
}
const resultUplodedDocuments = getNonNullValues(uploadedFiles);

console.log(sameCompany, 'sameCompany');

  const handleMapAddressSelect = useCallback(
    (mapAddress: MapAddress) => {
      console.log(mapAddress, 'mapAddress');
      setRegisterAddress(mapAddress.formattedAddress)
      setHasSearchAddress(true)
      setStreetNameAddress([mapAddress.streetNum, mapAddress.route].join(' '))
      setState(mapAddress.state)
      setPostalCode(mapAddress.postalCode)
      setCity(mapAddress.city)
      setLatitude(String(mapAddress.lat))
      setLongitude(String(mapAddress.long))
      setCountry(mapAddress.country);
    },
    [],
  )

  const handleInsuranceCheck = useCallback(
    (isChecked: boolean, insurance: string) => {
      if (isChecked) {
        if (!insurances.includes(insurance)) {
          insurances.push(insurance);
          setInsurances([...insurances]);
        }
      } else {
        setInsurances(insurances.filter((ins) => ins !== insurance));
      }
    },
    [insurances]
  );
  // Handle date change
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setSelectedDate(dateValue);
  };
  const handleDateChangeTerms = (event) => {
    const dateValue = event.target.value;
    setSelectedDateTerms(dateValue);
  };

  const handleCountryCheck = useCallback(
    (isChecked: boolean, area: string) => {
      if (isChecked) {
        if (!countryRegions.includes(area)) {
          countryRegions.push(area);
          setCountryRegions([...countryRegions]);
        }
      } else {
        setCountryRegions(countryRegions.filter((ins) => ins !== area));
      }
    },
    [countryRegions]
  );
const handleAddContact = () => {
  setContacts((prevContacts) => [
    ...prevContacts,
    { contactName: '', contactPhone: '', contactEmail: '' },
  ]);
};

const handleRemoveContact = (index) => {
  setContacts((prevContacts) =>
    prevContacts.filter((_, i) => i !== index)
  );
};

const handleInputChange = (index, field, value) => {
  setContacts((prevContacts) =>
    prevContacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    )
  );
};


  const handleFileUploadConsentSignature = (e: any) => {
    const file = e.target.files[0];
    setConsentCompanySignature(file);
  };
  const handleFileUploadTermsCompanySignature = (e: any) => {
    const fileSign = e.target.files[0];
    setTermsCompanySignature(fileSign);
  };

  


  

  
  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {

    try {
      if (!agreedTos) {
        toast.warning(t('tc_agree'));
        setSubmitting(false);
        return;
      }

     
      let resDataCompanySignature: any = undefined;

      let resDataconsentCompanySignature: any = undefined;
      let resDataconsentcompanySheetAttachment: any = undefined;



      if (termsCompanySignature) {

        const folderName = `termsCompanySignature`;

        resDataCompanySignature = await s3UploadMethod(
         [ termsCompanySignature],
          folderName,
          location => {
            resDataCompanySignature = location;
          },
          error => {
            console.error('Error uploading file:', error);
          }
        );


        console.log(resDataCompanySignature, "resData", termsCompanySignature);
      }

      if (consentCompanySignature) {

        const folderName = `consentCompanySignature`;

        resDataconsentCompanySignature = await s3UploadMethod(
          [consentCompanySignature],
          folderName,
          location => {
            resDataconsentCompanySignature = location;
          },
          error => {
            console.error('Error uploading file:', error);
          }
        );


        console.log(resDataCompanySignature, resDataconsentCompanySignature, "resData", termsCompanySignature);
      }

      if (resultUplodedDocuments) {

        const folderName = `consentCompanySignature`;

        resDataconsentcompanySheetAttachment = await s3UploadMethod(
          resultUplodedDocuments,
          folderName,
          location => {
            resDataconsentcompanySheetAttachment = location;
          },
          error => {
            console.error('Error uploading file:', error);
          }
        );
      }
      

console.log(adminEmail, 'adminEmail')


       await companyApi.register({
          username: values.adminName,
          email: values.adminEmail,
          firstName: values.adminName,
          lastName: checkIsEmpty(values.contactName),
          password: checkIsEmpty(values.password),
          password_confirm: checkIsEmpty(values.passwordConfirm),
          type: 'COMPANY',
          companyType:checkIsEmpty(values.companyType),
          companyName:checkIsEmpty(values.companyName),
          companyUen:checkIsEmpty(values.Abn_Acn),
          addressStreet: checkIsEmpty(values.RegisteredAddress),
          addressCity: checkIsEmpty(suburbOrCity),
          addressPostalCode: checkIsEmpty(postalCode),
          accountContactName: checkIsEmpty(values.contactName),
          accountContactPhone: checkIsEmpty(values.mobile),
          accountContactEmail: values.adminEmail,
          operationsContactName: values.contactName,
          operationsContactPhone: values.mobile,
          operationsContactEmail: values.adminEmail,
          promoCode:checkIsEmpty(promoCode),
          country: checkIsEmpty(country || ''),
          importerName:checkIsEmpty(importerName),
          importerEmail:checkIsEmpty(importerEmail),
          importerPhone:checkIsEmpty(importerPhone),
          exporterEmail:checkIsEmpty(exporterEmail),
          exporterName:checkIsEmpty(exporterName),
          exporterPhone:checkIsEmpty(exporterPhone),
          latitude:latitude?latitude:null,
          longitude:longitude?longitude:null,
          tradingName: checkIsEmpty(values.TradingName),
          website: checkIsEmpty(website),
          typeOfBusiness: values.TypeOfBussiness,
          productsHandled: values.ProductsHandled,
          statesTerritories: countryRegions ?? [],
          accountPayable: [{
            contactName: values.contactName,
            contactPhone: values.mobile,
            contactEmail: values.contactEmail,
          },] as any,
          siteAccess: [
            {
              adminName: values.adminName,
              adminPhone: values.mobile,
              adminEmail: values.adminEmail,
            },
          ] as any,
          requestedCreditLimit: values.operationscreditlimit?.length > 1 ?  values.operationscreditlimit : null ,
          preferredPaymentTerms: values.operationscredit,
          consentArn: checkIsEmpty(values.authorisedName),
          consentPositionTitle: checkIsEmpty(values.position),


        
          consentSignature: resDataconsentCompanySignature ?? [],
          termArn: checkIsEmpty(values.authorisedRepresentativeName),
        termPositionTitle: checkIsEmpty(values.positionTitle),
          termSignature: resDataCompanySignature ?? [],
          insurances,
          waitTime: checkIsEmpty(waitingTime),
          waitTimeCharge: checkIsEmpty(waitingTimeCharge),
          mobile:checkIsEmpty(mobile),
          registeredAddress: checkIsEmpty(''),
          postalAddress: checkIsEmpty(values.companyPostalAddress),
          emailAddress: values.email,
          phoneNumber: checkIsEmpty(values.mobile),
          consentDate: new Date(selectedDate),
          termDate: new Date(selectedDate),
          postalAddressCompany:companyPostalAddress?companyPostalAddress as any:null,
          postalPostcode:Postcode?Postcode:null,
          postalState:PostalState?PostalState:null,
          postalCountry:PostalCountry?PostalCountry:null,


          uploadDocuments: resDataconsentcompanySheetAttachment ?? []
        });

      toast.success(t('registration_success'));
      history.push(routes.landing.login[0]);
      resetForm();
    } catch (e: any) {
      toast.error(
        t(e.response?.data || 'an_error_occurred_while_registering')
      );
      console.error('Registration error:', e);
    } finally {
      setSubmitting(false);
    }
  };
  
  const handleCheckboxChange = (setFieldValue, values) => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setFieldValue('TradingName', values.companyName);
    } else {
      setFieldValue('TradingName', '');
    }
  };

//   useEffect(() => {
//     // Update the value of TradingName based on sameCompany
//     if (sameCompany) {
//         setFieldValue('TradingName', values?.companyName); // Set TradingName to companyName when sameCompany is true
//     } else {
//         setFieldValue('TradingName', values.TradingName); // Reset TradingName to its original value
//     }
// }, [sameCompany, setFieldValue, values.companyName, values.TradingName]);


useEffect(() => {
  if (sameRegister) {
    // Sync all values when `sameRegister` is true
    setCompanyPostalAddress(registerAddress);
    setPostcode(postalCode);
    setPostalState(State);
    setPostalCountry(country);
  }else{
    setCompanyPostalAddress('');
    setPostcode('');
    setPostalState('');
    setPostalCountry('');
  }
}, [sameRegister, registerAddress, postalCode, State, country, setCompanyPostalAddress, setPostcode, setPostalState, setPostalCountry]);

  const handleCheckboxAddress = () => {
    setIsCheckedAddress(!isCheckedAddress);
    if (!isCheckedAddress) {
      setCompanyPostalAddress(streetNameAddress); 
      setPostcode(postalCode); 
      setPostalState(State); 
      setPostalCountry(country); 
    } else {
      setCompanyPostalAddress('');
      setPostcode('');
      setPostalState('');
      setPostalCountry('');
    }
  };


  
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationCompanyRegister}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Container maxWidth="lg">
              <TermsOfServiceDialog
                agreed={agreedTos}
                onCheckBoxCheck={(agree) => {
                  setAgreedTos(agree);
                }}
                open={isTermsOfServiceDialogOpen}
                handleClose={handleTermsOfServiceDialogClose}
                country={country}
              />
              <Box className={classes.companyRegisterBox}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid md={12} item>
                    <Typography align="center" variant="h4" component="h4">
                      {t('company_registration')}
                    </Typography>
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                      value={values.companyName}
                      name='companyName'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('enter_company_name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.companyName && !!errors.companyName}
                      helperText={touched.companyName && errors.companyName}
                    />

                  </Grid>
                  <Grid md={4} item>
                    <TextField
                      value={values.TradingName  }
                      onChange={handleChange}
                      name='TradingName'
                      variant="outlined"
                      fullWidth
                      label={t('Trading Name')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.TradingName && !!errors.TradingName}
                      helperText={touched.TradingName && errors.TradingName}
                    />
                  </Grid>
                  
                  {/* <Grid md={6} item>
                        <TextField
                            value={companyUen}
                            onChange={(e) => setCompanyUen(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`${getLabelForRegistrationNumberByCountry(country)}`) + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}
                  <Grid md={4} item>
                    <TextField
                      value={values.Abn_Acn}
                      name='Abn_Acn'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('ABN/ACN') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.Abn_Acn && !!errors.Abn_Acn}
                      helperText={touched.Abn_Acn && errors.Abn_Acn}
                    />
                  </Grid>
                  <Grid md={10} item style={{ padding: '0px 0px 0px 0px' }}>
                    <div  className={classes.sameAsCompany}  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={sameCompany}
                            onChange={(e) =>{
                              console.log(e?.target?.checked, 'e?.target?.checked' );
                              if(e?.target?.checked){
                                
                                setFieldValue('TradingName', values?.companyName);
                              }else{
                                setFieldValue('TradingName', '');
                              }
                              setSameCompany(e?.target?.checked);
                            }
                            }
                            color="primary"
                          />
                        }
                        label={t('')}
                      />
                      <Typography className={classes.paragrapHeading2}>
           {t('Same as Company Name')}
         </Typography>
                    </div>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <MapAutocomplete setFieldValue={setFieldValue} error={touched.registerAddress && !!errors.registerAddress} helperText={touched.registerAddress && errors.registerAddress} onAddressSelect={handleMapAddressSelect} />
                    </Grid>
                    {/* <TextField
  value={streetNameAddress}
  onChange={(e) => setStreetNameAddress(e.target.value)}
  variant="outlined"
  fullWidth
  label={t("enter_street_num_n_name") + "*"}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <HomeIcon />
      </InputAdornment>
    ),
  }}
  InputLabelProps={{
    style: {
      fontWeight: "bold",
    },
  }}
/> */}
                 
                  {/* <Grid xs={12} lg={6} item>
                    <TextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('enter_suburb_or_city') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid> */}
                  <Grid xs={12} lg={4} item>
                    <TextField
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Enter postal code') }
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                      value={State}
                      onChange={(e) => setState(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('state')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.State && !!errors.State}
                      helperText={touched.State && errors.State}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('country')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={3} item>
                    <TextField
                      value={ companyPostalAddress}
                      onChange={(e) => setCompanyPostalAddress(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Postal Address (if different)')}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                      value={ Postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Postcode')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.Postcode && !!errors.Postcode}
                      helperText={touched.Postcode && errors.Postcode}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                      value={PostalState}
                      onChange={(e) => setPostalState(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('state')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      // error={touched.State && !!errors.State}
                      // helperText={touched.State && errors.State}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                      value={ PostalCountry}
                      onChange={(e) => setPostalCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('country')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid md={5}></Grid>
                  <Grid item md={3} className={classes.sameAsCompany} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={isChecked}
          color="primary"
          onChange={handleCheckboxAddress}
        />
        <Typography className={classes.paragrapHeading2}>
          {t('Same as Registered Address')}
        </Typography>
      </Grid>
                  <Grid md={4}></Grid> */}


                  <Grid md={12} item>
                  <div  className={classes.sameAsCompany}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={sameRegister}
                            onChange={(e) =>{

                              setSameRegister(e?.target?.checked);
                            }
                            }
                            color="primary"
                          />
                        }
                        label={t('')}
                      />
                      <Typography className={classes.paragrapHeading2}>
           {t('Same as Registered Address')}
            </Typography>
                    </div>
                  </Grid>






                  <Grid md={4} item>
                    <TextField
                      value={values.mobile}
                      onChange={handleChange}
                      name='mobile'
                      variant="outlined"
                      fullWidth
                      label={t('Enter Phone Number') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.mobile && !!errors.mobile}
                      helperText={touched.mobile && errors.mobile}
                    />
                  </Grid>

                  {/* <Grid md={4} item>
                    <TextField
                      value={values.email}
                      name='email'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      type="email"
                      label={t('provide_email_address') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid> */}
                  {/* <Grid md={6} item>
                        <TextField
                            value={confirmEmail}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_email_address') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}

                  {/* <Grid md={6} item>
                        <TextField
                            value={confirmMobile}
                            onChange={(e) => setConfirmMobile(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_mobile_number') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}

                  <Grid md={4} item>
                    <TextField
                      value={website}
                      onChange={(e) => {
                        if (e.target.value.length <= 80) {
                          setWebsite(e.target.value);
                        }
                      }}
                      variant="outlined"
                      fullWidth
                      label={t('Website')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  {/* <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setInsurances([])
                                }}
                            > */}
                  {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
                  {/* <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
                  {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
                  {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
                  {/* </Select>
                        </FormControl>
                    </Grid> */}
                  <Grid md={4} item>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel style={{ fontWeight: 'bold' }}>
                        {t('select_company_type') + '*'}
                      </InputLabel>
                      <Select
                        value={values.companyType}
                        onChange={handleChange}
                        name="companyType"
                        error={touched.companyType && !!errors.companyType}
                      >
                        <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                        <MenuItem value={FREIGHT_FORWARED}>
                          {t(FREIGHT_FORWARED)}
                        </MenuItem>
                        <MenuItem value={TRANSPORT_COMPANY}>
                          {t(TRANSPORT_COMPANY)}
                        </MenuItem>
                        <MenuItem value={IMPORT_EXPORTER}>
                          {t(IMPORT_EXPORTER)}
                        </MenuItem>
                        <MenuItem value={SHIPPING_LINE}>
                          {t(SHIPPING_LINE)}
                        </MenuItem>
                      </Select>
                      {touched.companyType && errors.companyType && (
                      <FormHelperText className={classes.cssFixes}>{errors.companyType}</FormHelperText> 
                        )}
                    </FormControl>
                  </Grid>

                  {/* <Grid md={4} item>
                    <TextField
                      value={RegisteredAddress}
                      onChange={(e) => setRegisteredAddress(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Registered Address') + '*'}
                      inputProps={{maxLength: 200}}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={
                        touched.RegisteredAddress && !!errors.RegisteredAddress
                      }
                      helperText={
                        touched.RegisteredAddress && errors.RegisteredAddress
                      }
                    />
                  </Grid> */}

                  {/* <Grid md={3} item>
                        <MapAutocomplete onAddressSelect={handleMapAddressSelect} />
                    </Grid> */}

                  {/* <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('address_details')}
                        </Typography>
                    </Grid> */}




                  


                  {/* <Grid md={6} item>
                    <TextField
                      value={values.firstName}
                      name='firstName'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('enter_first_name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.firstName && !!errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid md={6} item>
                    <TextField
                      value={values.lastName}
                      onChange={handleChange}
                      name='lastName'
                      variant="outlined"
                      fullWidth
                      label={t('enter_last_name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid> */}
                  {/* <Grid md={6} item>
                        <TextField
                            value={companyPostalAddress}
                            onChange={(e) => setCompanyPostalAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Postal Address (if different)')}
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}
                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>
                  {/* <Grid md={12} item>
                        <MapAutocomplete onAddressSelect={handleMapAddressSelect} />
                    </Grid> */}
                  {/* <Grid md={6} item>
                        <TextField
                            value={latitude}
                            variant="outlined"
                            fullWidth
                            label={t('latitude')}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={longitude}
                            variant="outlined"
                            fullWidth
                            label={t('longitude')}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    {/* <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setInsurances([])
                                }}
                            > */}
                  {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
                  {/* <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
                  {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
                  {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
                  {/* </Select>
                        </FormControl>
                    </Grid> */}
                  {/* <Grid md={6} item>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel style={{ fontWeight: 'bold' }}>{t('select_company_type') + "*"}</InputLabel>
                                        <Select
                                            value={companyType}
                                            onChange={(e) => setCompanyType(e.target.value as string)}
                                        >
                                            <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                            <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                            <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                            <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                            <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> */}

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('BUSINESS INFORMATION')}
                    </Typography>
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                      value={values.TypeOfBussiness}
                      onChange={handleChange}
                      name='TypeOfBussiness'
                      variant="outlined"
                      fullWidth
                      label={t('Type of Bussiness') + '*'}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.TypeOfBussiness && !!errors.TypeOfBussiness}
                      helperText={touched.TypeOfBussiness && errors.TypeOfBussiness}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                      value={values.ProductsHandled}
                      onChange={handleChange}
                      name='ProductsHandled'
                      variant="outlined"
                      fullWidth
                      label={t('Nature of Operations/Products handled')}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.ProductsHandled && !!errors.ProductsHandled}
                      helperText={touched.ProductsHandled && errors.ProductsHandled}
                    />
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.paragrapHeading}
                      align="left"
                      variant="body1"
                    >
                      {t(
                        'States/Territories of Operation (please select all that apply)' + '*'
                      ) }
                    </Typography>
                  </Grid>
                  {COUNTRYAREA.map((area, index) => (
                    <Grid key={index} item md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="selectedOptions"
                            value={area}
                            checked={values.selectedOptions.includes(area)}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={area}
                      />
                    </Grid>
                  ))}
                  {errors.selectedOptions && touched.selectedOptions && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {errors.selectedOptions}
                    </div>
                  )}

                  <Grid md={6} item>
                    {values.selectedOptions.includes('International') && (
                      <TextField
                        value={serviceRegionsInternational}
                        onChange={(e) =>
                          setServiceRegionsInternational(e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        label={t('Please Specify')}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('ACCOUNT PAYABLE')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                      Please add details for account related enquiries
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid md={12} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={isDetailsForAccountSame}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAccountName(`${firstName} ${lastName}`);
                            setAccountEmail(email);
                            setAccountPhone(mobile);
                          } else {
                            setAccountName('');
                            setAccountEmail('');
                            setAccountPhone('');
                          }
                          setIsDetailsForAccountSame(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Grid md={12} item>
                          <Typography
                            className={classes.title}
                            align="left"
                            variant="body1"
                          >
                            {t('ACCOUNT PAYABLE')}
                          </Typography>
                        </Grid>
                        <Typography className={classes.paragrapHeading2}>
                          {t(
                            'please add details for account related enquiries'
                          )}
                        </Typography>
                      </Box>
                    }
                  />
                </Grid> */}

                  <Grid item md={4}>
                    <TextField
                      value={values.contactName}
                      onChange={handleChange}
                      name='contactName'
                      variant="outlined"
                      fullWidth
                      label="Contact Name *"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.contactName && !!errors.contactName}
                      helperText={touched.contactName && errors.contactName}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      value={values.contactPhone}
                      onChange={handleChange}
                      name='contactPhone'
                      variant="outlined"
                      fullWidth
                      label="Phone Number *"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.contactPhone && !!errors.contactPhone}
                      helperText={touched.contactPhone && errors.contactPhone}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      value={values.contactEmail}
                      onChange={handleChange}
                      name='contactEmail'
                      variant="outlined"
                      fullWidth
                      type="email"
                      label="Email Address*"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.contactEmail && !!errors.contactEmail}
                      helperText={touched.contactEmail && errors.contactEmail}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    md={1}
                    style={{ textAlign: 'end', marginTop: '8px' }}
                  ></Grid> */}

                  {contacts?.length >= 1 && contacts.map((contact, index) => (
                  <Grid container style={{ marginLeft: '15px', gap: '36px',marginTop: '15px' }}>
                    <Grid item md={3}>
                      <TextField
                        value={contact.contactName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            'contactName',
                            e.target.value
                          )
                        }
                        variant="outlined"
                        fullWidth
                        label="Contact Name *"
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        value={contact.contactPhone}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            'contactPhone',
                            e.target.value
                          )
                        }
                        variant="outlined"
                        fullWidth
                        label="Phone Number *"
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        value={contact.contactEmail}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            'contactEmail',
                            e.target.value
                          )
                        }
                        variant="outlined"
                        fullWidth
                        label="Email Address *"
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      style={{textAlign: 'end', marginTop: '8px'}}
                    >
                      <IconButton onClick={() => handleRemoveContact(index)}>
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <div
                  style={{
                    marginTop: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton onClick={handleAddContact}>
                    <IoMdAdd />
                  </IconButton>
                  <Typography className={classes.paragrapHeading2}>
                    {t('Add additional Contact')}
                  </Typography>
                </div>

                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('MOVEIT4U SITE ACCESS')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(
                          `Please nominate an admin email address that will be used to manage your account within our software application.`
                        )}
                        <br />
                        {t(
                          `This admin will have the ability to create, edit, and manage additional users within your company profile.`
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                      value={values.adminName}
                      onChange={handleChange}
                      name='adminName'
                      variant="outlined"
                      fullWidth
                      label={t(' Admin Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.adminName && !!errors.adminName}
                      helperText={touched.adminName && errors.adminName}
                    />
                  </Grid>
                  <Grid md={6} item>
                    <TextField
                      value={values.adminEmail}
                      onChange={handleChange}
                      name='adminEmail'
                      variant="outlined"
                      fullWidth
                      type="email"
                      label={t('Admin Email') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.adminEmail && !!errors.adminEmail}
                      helperText={touched.adminEmail && errors.adminEmail}
                    />
                  </Grid>
                  {/* <Grid md={3} item>
                    <TextField
                      value={values.password}
                      name='password'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('enter_password') + '*'}
                      type={showPass ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass(!showPass)}>
                              {!showPass ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                      value={values.passwordConfirm}
                      name='passwordConfirm'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('confirm_password') + '*'}
                      type={showPass ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass(!showPass)}>
                              {!showPass ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.passwordConfirm && !!errors.passwordConfirm}
                      helperText={touched.passwordConfirm && errors.passwordConfirm}
                    />
                  </Grid> */}

                  <Grid md={6} item>
                    <TextField
                      value={values.password}
                      name='password'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('enter_password') + '*'}
                      type={showPass ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass(!showPass)}>
                              {!showPass ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                  <Grid md={6} item>
                    <TextField
                      value={values.passwordConfirm}
                      name='passwordConfirm'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('confirm_password') + '*'}
                      type={showPass ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass(!showPass)}>
                              {!showPass ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.passwordConfirm && !!errors.passwordConfirm}
                      helperText={touched.passwordConfirm && errors.passwordConfirm}
                    />
                  </Grid>

                  <Grid md={12} item>
                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                      Note:&nbsp;Logins and software access details will be provided once your account application has been approved.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={6} item>
                    {/* EMPTY */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('CREDIT TERMS')}
                    </Typography>
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                      value={values.operationscreditlimit}
                      name='operationscreditlimit'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('Requested Credit Limit (AUD)') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.operationscreditlimit && !!errors.operationscreditlimit}
                      helperText={touched.operationscreditlimit && errors.operationscreditlimit}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <Box display="flex" alignItems="center">
                      <TextField
                        value={values.operationscredit}
                        onChange={handleChange}
                        name='operationscredit'
                        variant="outlined"
                        fullWidth
                        label={t('Preferred Payment Terms') + '*'}
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                        error={touched.operationscredit && !!errors.operationscredit}
                        helperText={touched.operationscredit && errors.operationscredit}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: '8px', fontWeight: 'bold' }}
                      >
                        {t('days')}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('CREDIT CHECK CONSENT')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {/* {t(`add text from scoping here.`)}
                        <br /> */}
                        {/* {t(
                        `This admin will have the ability to create, edit, and manage additional users within your company profile.`
                      )} */}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                      value={values.authorisedName}
                      name='authorisedName'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('Authorised Representative Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.authorisedName && !!errors.authorisedName}
                      helperText={touched.authorisedName && errors.authorisedName}
                    />
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                      value={values.position}
                      name='position'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('Position/Title') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.position && !!errors.position}
                      helperText={touched.position && errors.position}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <FileInput
                      onChange={handleFileUploadConsentSignature}
                      label={t('Upload Signature') + '*'}
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid md={6} item>
                    <CustomDatePicker
                      selectedDate={selectedDate}
                      handleDateChange={handleDateChange}
                      readOnly={false}
                      placeholder="Date"
                      name="datePicker"
                      error={error}
                    />
                  </Grid>

                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  {/* <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body2"
                    >
                      {t('UPLOAD DOCUMENT')}
                    </Typography>
                    <FileInput
                      onChange={handleFileUploadCompanyRegister}
                      label={t('Upload Docs') }
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid> */}


                               <Grid md={12} item>


                               <div className={classess.line}></div>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                >
                                    UPLOAD DOCUMENTS
                                </Typography>
                                <div className={classess.flexColumn}>

                                    <div {...getRootProps({ className: 'dropzone' })} className={classess.draganddrop}>
                                        <input {...getInputProps()} />
                                        {file ? (
                                            <p>Selected file: {file.name}</p>
                                        ) : (
                                            <p>Drag 'n' drop to upload, or browse documents</p>
                                        )}
                                    </div>
                                    <SelectorComp
                                        label="Document Type"
                                        value={docType}
                                        onChange={handleDocTypeChange}
                                        options={DOCS}
                                        name="document"
                                    />
                                    <div className={classess.flexWrapperIcon} onClick={handleAddDocument}><AddIcon /><div>Add Documents</div></div>
                                    <div className={classess.previewTitle}>Attachments</div>
                                    <div className={classess.previewContainer}>
                                        {Object.entries(uploadedFiles).map(([type, uploadedFile]) => (
                                            uploadedFile && (
                                                <div key={type} className={classess.previewCard}>
                                                    <div className={classess.cardContent}>
                                                        <div className={classess.cardItemFileName}>
                                                            <strong>File:</strong> {uploadedFile.name}
                                                        </div>
                                                        <div className={classess.cardItemType}>
                                                            <strong>Type:</strong> {type}
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => handleDeleteFile(type as DocType)}
                                                        className={classess.deleteButton}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                                <div className={classess.line}></div>
                               </Grid>
                             {/* <div className={classess.line}></div> */}

                 
                             <Grid md={12} item>
  <Typography className={classes.title} align="left" variant="body1">
    {t('TERMS AND CONDITIONS')}
  </Typography>
  <React.Fragment>
    {TermsAndConditions()}
  </React.Fragment>
</Grid>
                  <Grid md={6} item>
                    <TextField
                      value={values.authorisedRepresentativeName}
                      name='authorisedRepresentativeName'
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('Authorised Representative Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.authorisedRepresentativeName && !!errors.authorisedRepresentativeName}
                      helperText={touched.authorisedRepresentativeName && errors.authorisedRepresentativeName}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                      value={values.positionTitle}
                      name='positionTitle'  
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      label={t('Position/Title') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                      error={touched.positionTitle && !!errors.positionTitle}
                      helperText={touched.positionTitle && errors.positionTitle}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <FileInput
                      onChange={handleFileUploadTermsCompanySignature}
                      label={t('Upload Signature') + '*'}
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid md={6} item>
                    <CustomDatePicker
                      selectedDate={selectedDateTerms}
                      handleDateChange={handleDateChangeTerms}
                      readOnly={false}
                      placeholder="Date"
                      name="datePicker"
                      error={error}
                    />
                  </Grid>
                  {/* <Grid md={12} item>
        <TextField
          multiline
          rows={6} // Increase the row count if needed
          variant="outlined"
          fullWidth
          label="Terms and Conditions"
          value={termsText} // Set the returned text as the value of TextField
          InputProps={{
            readOnly: true, // Make the TextField read-only
          }}
        />
      </Grid> */}




                  {/* <Grid md={12} item>
                                    <Typography className={classes.title} align="left" variant="body1">
                                        {t('address_details')}
                                    </Typography>
                                </Grid>
                                <Grid md={12} item>
                                    <TextField
                                        value={streetAndName}
                                        onChange={(e) => setStreetAndName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('enter_street_num_n_name') + "*"}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={suburbOrCity}
                                        onChange={(e) => setSuburbOrCity(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('enter_suburb_or_city') + "*"}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('enter_postal_code') + "*"}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid> */}

                  <Grid md={6} item>
                    {/* EMPTY */}
                  </Grid>
                  {/* <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('please_confirm_your_insurance_coverage')}
                        </Typography>
                    </Grid>
                    {INSURANCES.map((insurance, i) => (
                        <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                            <FormControlLabel
                                control={<Checkbox checked={insurances.includes(insurance)} onChange={(e) => handleInsuranceCheck(e.target.checked, insurance)} color="primary" />}
                                label={t(insurance)}
                            />
                        </Grid>
                    ))}
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}

                  <Grid md={6} item>
                    {/* EMPTY */}
                  </Grid>

                  <Grid md={12} item>
                    {/* EMPTY */}
                  </Grid>

                  {/* seema code complete */}

                  {[NVOCC, FREIGHT_FORWARED].includes(values.companyType) ? (
                    <>
                      <Grid md={12} item>
                        <Typography
                          className={classes.title}
                          align="left"
                          variant="body1"
                        >
                          {t('importer_details')}
                        </Typography>
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={importerName}
                          onChange={(e) => setImporterName(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_importer_name')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={importerPhone}
                          onChange={(e) => setImporterPhone(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_importer_phone')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={importerEmail}
                          onChange={(e) => setImporterEmail(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_importer_email')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        {/* EMPTY */}
                      </Grid>
                      <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                      </Grid>
                      <Grid md={12} item>
                        <Typography
                          className={classes.title}
                          align="left"
                          variant="body1"
                        >
                          {t('exporter_details')}
                        </Typography>
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={exporterName}
                          onChange={(e) => setExporterName(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_exporter_name')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={exporterPhone}
                          onChange={(e) => setExporterPhone(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_exporter_phone')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        <TextField
                          value={exporterEmail}
                          onChange={(e) => setExporterEmail(e.target.value)}
                          variant="outlined"
                          fullWidth
                          label={t('provide_exporter_email')}
                          InputLabelProps={{
                            style: {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </Grid>
                      <Grid md={6} item>
                        {/* EMPTY */}
                      </Grid>
                      <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                      </Grid>
                    </>
                  ) : null}

                  {/* <Grid md={12} item>
              <Typography
                className={classes.title}
                align="left"
                variant="body1"
              >
                {t('promo_code')}
              </Typography>
            </Grid> */}

                  {/* <Grid md={6} item>
              <TextField
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('enter_promotion_code')}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid> */}
                  <Grid md={6} item>
                    {/* EMPTY */}
                  </Grid>
                  <Grid md={11} item>
                    <Typography variant="caption" display="block" gutterBottom>
                      {t('i_agree_to_tos')}{' '}
                      <button
                        onClick={() => setIsTermsOfServiceDialogOpen(true)}
                        className={classes.termsOfServiceBtn}
                      >
                        {t('click_here_to_read_tos')}
                      </button>{' '}
                      {t('and_our')}
                      <a
                        rel="noreferrer"
                        href="https://moveit4u.com.au/privacypolicy/"
                        target="_blank"
                      >
                        {t('privacy_policy')}
                      </a>{' '}
                      {t('for_the_use_of_the_moveit4u_website_and_mobile_app')}{' '}
                      <a
                        rel="noreferrer"
                        href="https://moveit4u.com.au/pricing/"
                        target="_blank"
                      >
                        {t('pricing_page')}
                      </a>
                      .
                    </Typography>
                  </Grid>
                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>
                  <Grid md={6} item>
                    <Button
                      startIcon={<DoneOutlineIcon />}
                      className={classes.btnRegister}
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {t('register')}
                    </Button>
                  </Grid>
                  <Grid md={6} item>
                    <Button
                      startIcon={<ArrowBackIcon />}
                      className={classes.btnBack}
                      variant="contained"
                      fullWidth
                      onClick={handleBackButtonClick}
                    >
                      {t('back')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>

            </Container>
          </form>
          // </Container>
        )}
      </Formik>
    </>
  );
}

export default CompanyRegister;
