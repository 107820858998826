import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import useDebounce from '../../hooks/useDebounce';
import { Box, Button } from '@material-ui/core';
import { formatDateToMMDDYYYY, getTodayDate } from '../../lib/helper';
import { format } from 'date-fns';
import CustomDatePickerNew from '../../ui/CustomDatePickerNew';
import JobStatusDropdown from '../../ui/JobStatusDropdown/JobStatusDropdown';
import { AdminModalsState } from './JobsToBeAssignedV2';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GenerateJobPdf from '../../modals/GenerateJobPdf';
import DeleteJobModal from '../../modals/DeleteJobModal';
import ViewJobModal from '../../modals/ViewJobModal';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
    },
    dateContainer: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    button: {
        height: '100%',
        whiteSpace: 'nowrap',
        width: '80%',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    },
    dropdown_container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        marginBottom:"6px !important"
    },
    actions: {
        display: "flex !important",
        gap: "12px !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: "100% !important",
    },
}));

const storage = new WebStorage();

const ScheduleDeliveriesV2 = (props: any) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const [statusData, setStatusData] = useState<any[]>([]);
    const [assignedData, setAssignedData] = useState(null);
    const [modals, setModals] = useState<AdminModalsState>({
        modal2:false,  //delete modal
        modal3: false,  //pdf generate
        modal4: false   //view modal
    });
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchJobs();
    }, [debouncedSearch, fromDate, toDate]);

    useEffect(() => {
        fetchStatusApi()
    }, [])

    const fetchStatusApi = async () => {
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + `/v3/status?page=deliveries`,
                requestOptions
            );

            const result = await response.json();
            setStatusData(result.statuses)
        } catch (err) {
            console.error('Fetch error:', err);
        } 
    };

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

            const formattedFromDate = fromDate ? format(new Date(fromDate), 'dd/MM/yyyy') : null;
            const formattedToDate = toDate ? format(new Date(toDate), 'dd/MM/yyyy') : null;

            const filters = JSON.stringify({ reference: debouncedSearch, createdDate: { "to": formattedToDate, "from": formattedFromDate } });
            const encodedFilters = encodeURIComponent(filters);

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + `/v1/job/get/optimize?classification=transport&page=scheduled_deliveries&offset=0&filters=${encodedFilters}`,
                requestOptions
            );


            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();

            if (!result?.jobList || result.jobList.length === 0) {
                setData((prevData) => ({
                    ...prevData,
                    rows: [],
                    totalRows: 0,
                }));
                return;
            }

            const formattedRows = result.jobList.map((job: any) => ({
                id: job.id,
                company: job.company?.name,
                customer: job.customer,
                carrier:job?.assigned_user?.accountcontactname,
                actual_collection_date:"",
                actual_collection_time:"",
                scheduled_delivery_date:formatDateToMMDDYYYY(job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[0]) ?? '',
                actual_delivery_date:"",
                customer_reference: job.customer_reference,
                shipment_no: job.shipment_no,
                service_type: job.service_type,
                status: job.status,
                created_at: formatDateToMMDDYYYY(job.created_at?.split("T")[0]),
            }));

            setData((prevData) => ({
                ...prevData,
                rows: formattedRows,
                totalRows: result.jobList.length,
            }));
        } catch (err) {
            console.error('Fetch error:', err);
            setData((prevData) => ({
                ...prevData,
                rows: [],
                totalRows: 0,
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus =async (statusContraint: string, id: string | number) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "job_id":id,
                 "status":statusContraint
            });
            const requestOptions: RequestInit = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };
            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/job/update/status", requestOptions);
            const result = await response.json();
            if (result.status_code === 201 && result.success === true) {
              toast.success("Job status updated successfully");
              fetchJobs()
            }
          } catch (error) {
            toast.error(error.message);
          }
    }


    const formattedStatus = useMemo(() => {    
        return statusData.map((item) => ({
            id: item.statusConstraint,     
            value: item.statusName,  
            label: item.statusName,  
        }));
    }, [statusData]);

    const columns: GridColDef[] = useMemo(() => [
      {
            field: 'status', headerName: 'Status', align: 'center', headerAlign: 'center', minWidth: 250, sortable: false,
            renderCell: (params) => {
                const currentStatus = params.row.status==="pendingCollection"?"":(params.row.status || "");
                return (
                    <div className={classes.dropdown_container}>
                        <JobStatusDropdown
                            options={formattedStatus}
                            selectedValue={currentStatus}
                            onChange={(statusname:string) => handleChangeStatus(statusname, params.row.id)}
                            placeholder="Select Status"
                        />
                    </div>
                );
            },
        }, 
        { field: 'company', headerName: 'Company', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'customer', headerName: 'Customer', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'carrier', headerName: 'Carrier', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'actual_collection_date', headerName: 'Actual Collection Date', minWidth: 230, align: 'center', headerAlign: 'center' },
        { field: 'actual_collection_time', headerName: 'Actual Collection Time ', minWidth: 230, align: 'center', headerAlign: 'center' },
        { field: 'scheduled_delivery_date', headerName: 'Scheduled Delivery Date', minWidth: 230, align: 'center', headerAlign: 'center' },
        { field: 'actual_delivery_date', headerName: 'Actual Delivery Date', minWidth: 230, align: 'center', headerAlign: 'center' },
        { field: 'customer_reference', headerName: 'Customer Reference', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'shipment_no', headerName: 'Shipment No.', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'service_type', headerName: 'Service Type', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'created_at', headerName: 'Created At', align: 'center', headerAlign: 'center', minWidth: 150 },
        {
            field: 'action', headerName: 'Action', align: 'center', headerAlign: 'center', minWidth: 200, sortable: false,
            renderCell: (params) => (
                <div className={classes.actions}>
                    <Link to={`edit/${params.row.id}`}
                        style={{ lineHeight: 0 }}
                    >
                        <EditIcon
                            aria-label="Edit Job"
                            style={{ color: "#64748b", cursor: "pointer" }}
                            fontSize='small'
                        />
                    </Link>
                    <PictureAsPdfIcon
                        onClick={() => { toggleModal("modal3"); setAssignedData(params.row); }}
                        style={{ color: "#f24646", cursor: "pointer" }}
                        fontSize='small'
                        aria-label="PDF Job"
                    />
                    <DeleteIcon
                        onClick={() => { toggleModal("modal2"); setAssignedData(params.row); }}
                        style={{ color: "#dc2626", cursor: "pointer" }}
                        fontSize='small'
                        aria-label="Delete Job"
                    />
                      <RemoveRedEyeIcon
                        onClick={() => { toggleModal("modal4"); setAssignedData(params.row); }}
                        style={{ color: "#84cc16", cursor: "pointer" }}
                        fontSize='small'
                        aria-label="View Job"
                    />
                </div>
            ),
        },
    ], [statusData]);

    const toggleModal = useCallback(
        (modalName: keyof AdminModalsState) => {
            setModals((prevState) => ({
                ...prevState,
                [modalName]: !prevState[modalName],
            }));
        },
        [setModals]
    );

    const handleSearch = (query: string) => {
        setSearch(query);
    };

    const handleClearDates = useCallback(() => {
        if (fromDate || toDate || search) {
            setFromDate(null);
            setToDate(null);
            setSearch('');
        }
    }, [fromDate, toDate, search]);

    return (
        <>
         <GenerateJobPdf
                open={modals.modal3}
                close={() => toggleModal("modal3")}
                assignedData={assignedData}
                fetchJobs={fetchJobs}
            />
            <DeleteJobModal
              open={modals.modal2}
              close={() => toggleModal("modal2")}
              assignedData={assignedData}
              fetchJobs={fetchJobs}
            />
                <ViewJobModal    
             open={modals.modal4}
                close={() => toggleModal("modal4")}
                assignedData={assignedData}
            />
            <SectionHeader subtitle="Transport Management" title="Schedule Deliveries" />
            <Box className={classes.searchContainer}>
                <Box className={classes.dateContainer}>
                    <CustomDatePickerNew
                        selectedDate={fromDate}
                        handleDateChange={(date: string) => setFromDate(date)}
                        maxDate={getTodayDate()}
                        name="fromDate"
                        placeholder="From"
                    />

                    <CustomDatePickerNew
                        selectedDate={toDate}
                        handleDateChange={(date: string) => setToDate(date)}
                        maxDate={getTodayDate()}
                        name="toDate"
                        placeholder="To"
                        minDate={fromDate}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={handleClearDates}
                        disabled={!fromDate && !toDate && search === ''}
                    >
                        Clear Filters
                    </Button>
                </Box>
                <CustomSearchInput label="Search by reference" onSearch={handleSearch} variant="outlined" value={search} />
            </Box>
            {data?.rows?.length === 0 && !loading && <div className={classes.errorMessage}>No jobs found. </div>}
            <div className={classes.root}>
                <DataGrid
                    paginationMode="client"
                    sortingMode="client"
                    rows={data.rows}
                    columns={columns}
                    pageSize={data.pageSize}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    pagination
                    page={data.page - 1}
                    loading={loading}
                    disableSelectionOnClick
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    autoHeight
                    rowCount={data.totalRows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                />
            </div>
        </>
    );
};

export default ScheduleDeliveriesV2;
