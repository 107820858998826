import { Dialog, DialogContent, DialogTitle, Typography, FormControlLabel, Switch, FormGroup, Checkbox, DialogActions, Grid, Button, FormControl, InputLabel, MenuItem, Select, makeStyles, TextField, InputAdornment, Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COMPANY_TYPES, COUNTRIES, STATUS, TStatus, COUNTRY_INSURANCES, REGIONS_COUNTRIES } from "../../../Types/Constants"
import { IViewCompanyDetailsDialogProps } from "./ViewCompanyDetailsDialog.types"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { useCallback, useEffect, useState } from "react";
import { Company } from "../../../Api/Company/Company";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { ICompanyInfo, ICompanyUpdatePayload } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningDialog from "../WarningDialog/WarningDialog";
import { isNumeric } from "../../../Utilities/Regex";
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import ChangePassword from "./ChangePassword"
import SwitchButton from '../../Common/SwitchButton/SwitchButton'
import FileInput from "../../Common/FileInput/FileInput";
import CustomDatePicker from "../../../ComponentsV2/ui/CustomDatePicker";
import { checkIsEmpty } from "../../../ComponentsV2/lib/helper";
import classess from "../../../../src/ComponentsV2/styles/createjobv2.module.css"
import { DOCS } from "../../../ComponentsV2/lib/constants";
import { useDropzone } from "react-dropzone/.";
import AddIcon from '@material-ui/icons/Add';
import SelectorComp from "../../../ComponentsV2/ui/SelectorComp";
import { s3UploadMethod } from "../../../Utilities/awsFileUploder/awsFileUploder";


const {
    ACTIVE,
    INACTIVE
} = STATUS

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    },
    paragrapHeading: {
        fontWeight: 'normal',
      },
      paragrapHeading2: {
        fontWeight: 'normal',
        fontSize: '12px',
      },
}))

const { FREIGHT_FORWARED, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY, NVOCC } = COMPANY_TYPES

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const companyApi = new Company()
type DocType = typeof DOCS[number]['value'];

function ViewCompanyDetailsDialog(props: IViewCompanyDetailsDialogProps) {
    const { open, handleClose, selectedCompanyId } = props
    const [t] = useTranslation('ViewCompanyDetailsDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    const [accountName, setAccountName] = useState('')
    const [accountPhone, setAccountPhone] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [operationsName, setOperationsName] = useState('')
    const [operationsPhone, setOperationsPhone] = useState('')
    const [operationsEmail, setOperationsEmail] = useState('')
    const [country, setCountry] = useState(AUSTRALIA)
    const [companyType, setCompanyType] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyUen, setCompanyUen] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyMobile, setCompanyMobile] = useState('')
    const [streetName, setStreetName] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const [insurances, setInsurances] = useState<string[]>([])
    const [waitingTime, setWaitingTime] = useState('')
    const [waitingTimeCharge, setWaitingTimeCharge] = useState('')
    const [newPasswod, setNewPasswod] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [latitude, setLatitude] = useState<any>('')
    const [longitude, setLongitude] = useState<any>('')

    const [importerName, setImporterName] = useState('')
    const [importerPhone, setImporterPhone] = useState('')
    const [importerEmail, setImporterEmail] = useState('')
    const [exporterName, setExporterName] = useState('')
    const [exporterPhone, setExporterPhone] = useState('')
    const [exporterEmail, setExporterEmail] = useState('')
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [emailNotification, setEmailNotification] = useState<string[]>([])
    const [TradingName, setTradingName] = useState('');
    const [ratesAccess, setRatesAccess] = useState<boolean>(false)
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [streetNameAddress, setStreetNameAddress] = useState("");
    const [State, setState] = useState('');
    const [Abn_Acn, setAbn_Acn] = useState('');
    const INSURANCES = COUNTRY_INSURANCES[country] ?? []
    const [companyPostalAddress, setCompanyPostalAddress] = useState('');
    const [Postcode, setPostcode] = useState('');
    const [PostalState, setPostalState] = useState('');
    const [PostalCountry, setPostalCountry] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [TypeOfBussiness, setTypeOfBussiness] = useState('');
    const [ProductsHandled, setProductsHandled] = useState('');
    const COUNTRYAREA = REGIONS_COUNTRIES?.new_south_wales ?? [];
    const [countryRegions, setCountryRegions] = useState<any>([]);
    const [serviceRegionsInternational, setServiceRegionsInternational] =
      useState('');
      const [contactName, setContactName] = useState('')
      const [contactPhone, setContactPhone] = useState('')
      const [contactEmail, setContactEmail] = useState('')
      const [adminName, setAdminName] = useState('');
      const [adminPhone, setAdminPhone] = useState('');
      const [adminEmail, setAdminEmail] = useState('');
      const [operationscreditlimit, setCreditLimit] = useState<any>();
      const [operationscredit, setCredit] = useState<any>();
      const [authorisedName, setAuthorisedName] = useState('');
      const [position, setPosition] = useState('');
      const [consentCompanySignature, setConsentCompanySignature] = useState<any>();
      const [termsCompanySignature, setTermsCompanySignature] =
      useState<any>('');
      const [companySheetAttachment, setCompanySheetAttachment] = useState<any>();
      const [authorisedTermsName, setAuthorisedTermsName] = useState('');
      const [positionTerms, setPositionTerms] = useState('');
      const [error, setError] = useState(false);
      const [selectedDateTerms, setSelectedDateTerms] = useState('');
      const [selectedDate, setSelectedDate] = useState('');


      const [docType, setDocType] = useState<DocType | ''>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileContainer, setFileContainer] = useState<File | null>(null);
  const [docTypeContainer, setDocTypeContainer] = useState<DocType | ''>('');
  const [changeMi4u, setChangeMi4u] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<Record<DocType, File | null>>(
      DOCS.reduce((acc, doc) => {
          acc[doc.value as DocType] = null;
          return acc;
      }, {} as Record<DocType, File | null>)
  );





  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
    }
};

const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
});

const handleAddDocument = () => {
    if (docType && file) {
        setUploadedFiles((prevFiles) => ({
            ...prevFiles,
            [docType]: file,
        }));
        setDocType('');
        setFile(null);
    } else {
        toast.error('Please select a document type and upload a file.');
    }
};
const handleDeleteFile = (type: DocType) => {
    setUploadedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
    }));
};

const handleDocTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setDocType(event.target.value as DocType);
};


function getNonNullValues(data) {
  const nonNullValues = [];

  for (const key in data) {
      if (data[key] !== null) {
          nonNullValues.push(data[key]);
      }
  }

  return nonNullValues;
}
const resultUplodedDocuments = getNonNullValues(uploadedFiles);


const handleFileUploadConsentSignature = (e: any) => {
  const file = e.target.files[0];
  setConsentCompanySignature(file);
};
      const handleFileUploadCompanyRegister = (e: any) => {
        setCompanySheetAttachment(e.target.value);
      };
      const handleFileUploadTermsCompanySignature = (e: any) => {
        const fileSign = e.target.files[0];
        setTermsCompanySignature(fileSign);
      };
    const handleOnWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )

    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )

    const handlePasswordOpen = useCallback(
        () => {
            setShowChangePassword(true)
        },
        [],
    )
    const handlePasswordClose = useCallback(
        () => {
            setShowChangePassword(false)
        },
        [],
    )


    const handleChangePasswordSubmit = useCallback(
        (password: any) => {
            // dispatch(setLoader(true))
            companyApi.changepassword(selectedCompanyId, password)
                .then(() => {
                    handlePasswordClose()
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.success(t('success_changing_password'))
                    // dispatch(setLoader(false))
                })
                .catch((err: any) => {
                    handlePasswordClose()
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.error(t('error_changing_password'))
                    // dispatch(setLoader(false))
                })
        },
        [t, selectedCompanyId, handlePasswordClose, handleOnWarningDialogClose],
    )


    const handleOnProceedDelete = useCallback(
        () => {
            dispatch(setLoader(true))
            companyApi.delete(selectedCompanyId)
                .then(() => {
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))
                    toast.success(t('success_deleting_company'))
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_deleting_company'))
                })
        },
        [dispatch, t, selectedCompanyId, handleClose, handleOnWarningDialogClose],
    )


    const handleOnDeleteClick = () => {
        setIsWarningDialogOpen(true)
    }

    const handleOnUpdateClick = async() => {
        // if (country === '') {
        //     toast.warning(t('country_cannot_be_empty'))
        //     return
        // }

        // if (companyType === '') {
        //     toast.warning(t('company_type_not_empty'))
        //     return
        // }

        // if (companyName === '') {
        //     toast.warning(t('company_name_not_empty'))
        //     return
        // }

        // if (country === PHILIPPINES) {
        //     if (companyUen === '') {
        //         toast.warning(t('tin_not_empty'))
        //         return
        //     } else if (!isNumeric(companyUen)) {
        //         toast.warning(t('invalid_tin'))
        //         return
        //     }

        // }

        // if (companyUen === '') {
        //     if (country === AUSTRALIA) {
        //         toast.warning(t('abn_not_empty'))
        //         return
        //     } else if (country === JAPAN) {
        //         toast.warning(t('company_registration_number_not_empty'))
        //         return
        //     } else {
        //         toast.warning(t('eun_not_empty'))
        //         return
        //     }
        // }

        // if (companyEmail === '') {
        //     toast.warning(t('email_not_empty'))
        //     return
        // } else if (!isEmail(companyEmail)) {
        //     toast.warning(t('email_invalid_format'))
        //     return
        // }

        // if (companyMobile === '') {
        //     toast.warning(t('mobile_not_empty'))
        //     return
        // } else if (!isNumeric(companyMobile)) {
        //     toast.warning(t('mobile_not_number'))
        //     return
        // }

        // if (password === '' || passwordConfirm === '') {
        //     toast.warning(t('password_not_null'))
        //     return
        // } else if (password.length < 6) {
        //     toast.warning(t('invalid_password_length'))
        //     return
        // } else if (password !== passwordConfirm) {
        //     toast.warning(t('password_did_not_match'))
        //     return
        // }

        // if (streetAndName === '') {
        //     toast.warning(t('street_name_not_empty'))
        //     return
        // }

        // if (suburbOrCity === '') {
        //     toast.warning(t('suburb_city_not_empty'))
        //     return
        // }

        // if (postalCode === '') {
        //     toast.warning(t('postcode_not_empty'))
        //     return
        // }

        // if (accountName === '') {
        //     toast.warning(t('account_name_not_empty'))
        //     return
        // }

        // if (accountPhone === '') {
        //     toast.warning(t('account_phone_not_empty'))
        //     return
        // } else if (!isNumeric(accountPhone)) {
        //     toast.warning(t('account_phone_invalid'))
        //     return
        // }

        // if (accountEmail === '') {
        //     toast.warning(t('account_email_not_empty'))
        //     return
        // } else if (!isEmail(accountEmail)) {
        //     toast.warning(t('account_invalid_email'))
        //     return
        // }


        // if (insurances.length === 0) {
        //     toast.warning(t('select_coverage'))
        //     return
        // }

        // if (operationsName === '') {
        //     toast.warning(t('operation_name_not_empty'))
        //     return
        // }

        // if (operationsPhone === '') {
        //     toast.warning(t('operation_phone_not_empty'))
        //     return
        // } else if (!isNumeric(operationsPhone)) {
        //     toast.warning(t('operation_phone_invalid'))
        //     return
        // }

        // if (operationsEmail === '') {
        //     toast.warning(t('operation_email_not_empty'))
        //     return
        // } else if (!isEmail(operationsEmail)) {
        //     toast.warning(t('operation_invalid_email'))
        //     return
        // }

        // if (companyType === TRANSPORT_COMPANY && (waitingTime !== '' || waitingTimeCharge !== '')) {
        //     if (!isNumeric(waitingTime)) {
        //         toast.warning(t('waiting_time_invalid'))
        //         return
        //     } else if (!isNumeric(waitingTimeCharge)) {
        //         toast.warning(t('waiting_time_charge_invalid'))
        //         return
        //     }
        // }

        if (newPasswod !== '' || confirmPassword !== '') {
            if (newPasswod !== confirmPassword) {
                toast.warning(t('password_do_not_match'))
                return
            }

            if (newPasswod.length < 6) {
                toast.warning(t('passwords_length_too_short'))
                return
            }
        }
        let resDataCompanySignature: any = undefined;

        let resDataconsentCompanySignature: any = undefined;
        let resDataconsentcompanySheetAttachment: any = undefined;
  
  
  
        if (termsCompanySignature) {
  
          const folderName = `termsCompanySignature`;
  
          resDataCompanySignature = await s3UploadMethod(
           [ termsCompanySignature],
            folderName,
            location => {
              resDataCompanySignature = location;
            },
            error => {
              console.error('Error uploading file:', error);
            }
          );
  
  
          
        }
  
        if (consentCompanySignature) {
  
          const folderName = `consentCompanySignature`;
  
          resDataconsentCompanySignature = await s3UploadMethod(
            [consentCompanySignature],
            folderName,
            location => {
              resDataconsentCompanySignature = location;
            },
            error => {
              console.error('Error uploading file:', error);
            }
          );
  
  
        
        }
  
        if (resultUplodedDocuments) {
  
          const folderName = `consentCompanySignature`;
  
          resDataconsentcompanySheetAttachment = await s3UploadMethod(
            resultUplodedDocuments,
            folderName,
            location => {
              resDataconsentcompanySheetAttachment = location;
            },
            error => {
              console.error('Error uploading file:', error);
            }
          );
        }


        if (selectedCompanyId) {
            const payload: ICompanyUpdatePayload = {
                email: companyEmail,
                accountContactEmail: accountEmail,
                accountContactName: accountName,
                accountContactPhone: accountPhone,
                operationsContactEmail: operationsEmail,
                operationsContactName: operationsName,
                operationsContactPhone: operationsPhone,
                addressCity: city,
                addressStreet: streetName,
                addressPostalCode: postalCode,
                companyName: companyName,
                companyType: companyType,
                companyUen: companyUen,
                country: country,
                mobile: companyMobile,
                insurances,
                waitTime: waitingTime,
                waitTimeCharge: waitingTimeCharge,
                exporterEmail,
                exporterName,
                exporterPhone,
                importerEmail,
                importerName,
                importerPhone,
                newPassword: newPasswod === '' ? undefined : newPasswod,
                confirmPassword: confirmPassword === '' ? undefined : confirmPassword,
                email_notifications: emailNotification,
                rates_management_access: ratesAccess,
                latitude,
                longitude,
                tradingName:TradingName,
                website: "",
                typeOfBusiness: "",
                productsHandled: "",
                statesTerritories: countryRegions ?? [],
                accountPayable: [{
                  contactName: contactName,
                  contactPhone:mobile,
                  contactEmail:contactEmail,
                },] as any,
                siteAccess: [
                  {
                    adminName: adminName,
                    adminPhone: mobile,
                    adminEmail: adminEmail,
                  },
                ] as any,
                requestedCreditLimit: operationscreditlimit?.length > 1 ? operationscreditlimit : null,
                preferredPaymentTerms: operationscredit,
                consentArn: "",
                consentPositionTitle: "",
                consentSignature: resDataconsentCompanySignature ?? [],
                termArn: "",
                termPositionTitle: "",
                termSignature: resDataCompanySignature ?? [],
                postalAddressCompany: "",
                postalPostcode: "",
                postalState: "",
                postalCountry: "",
                firstName: "",
                password: "",
                password_confirm: "",
                type: "",
                uploadDocuments: resDataconsentcompanySheetAttachment ?? []
            }
            dispatch(setLoader(true))
            companyApi.update(selectedCompanyId, payload)
                .then(() => {
                    dispatch(setLoader(false))
                    setIsEditMode(false)
                    getCompanyDetails(selectedCompanyId)
                    toast.success(t('success_updating_company_config'))
                    handleClose()

                })
                .catch((err: any) => {
                    dispatch(setLoader(false))

                    if (err.response.data.message != "Email address already exist") {
                        toast.error(t('error_updating_company_details'))
                    }
                    else {
                        toast.error(err.response.data.message)


                    }
                })
        }
    }

    const setCompanyDetails = useCallback(
        (company: ICompanyInfo) => {
            setCompanyName(company.companyName)
            setCountry(company.country)
            setCompanyType(company.companyType)
            setCompanyUen(company.companyUen)
            setCompanyEmail(company.user?.email || '')
            setCompanyMobile(company.mobile)
            setStreetName(company.addressStreet)
            setCity(company.addressCity)
            setPostalCode(company.addressPostalCode)
            setAccountName(company.accountContactName)
            setAccountPhone(company.accountContactPhone)
            setAccountEmail(company.accountContactEmail)
            setOperationsName(company.operationsContactName)
            setOperationsEmail(company.operationsContactEmail)
            setOperationsPhone(company.operationsContactPhone)
            setInsurances(company.insurances)
            setWaitingTime(company?.waitTime || '')
            setWaitingTimeCharge(company?.waitTimeCharge || '')
            setImporterEmail(company.importerEmail ?? '')
            setImporterName(company.importerName ?? '')
            setImporterPhone(company.importerPhone ?? '')
            setExporterEmail(company.exporterEmail ?? '')
            setExporterName(company.exporterName ?? '')
            setExporterPhone(company.exporterPhone ?? '')
            setLatitude(company.latitude)
            setLongitude(company.longitude)
            setRatesAccess(company.rates_management_access)
            setTypeOfBussiness(company.typeOfBusiness)
            setCompanyPostalAddress(company.postalAddressCompany ?? null)
            setPostcode(company.postalPostcode ?? '')
            setPostalState(company.postalState ?? '')
            setPostalCountry(company.postalCountry ?? '')
            setMobile(company.accountContactPhone ?? '')
            setEmail(company.accountContactEmail ??'')
            setWebsite(company.website ?? '')
            setProductsHandled(company.productsHandled ?? '' )
            setAbn_Acn(company.companyUen ?? '')
            setContactName (company.accountContactName ?? '')
            setContactPhone(company.accountContactPhone ?? '')
            setContactEmail(company.accountContactEmail ?? '')
            setAdminName(company.siteAccess?.[0].adminName ?? '')
            setAdminPhone(company.siteAccess?.[0].adminPhone ?? '')
            setAdminEmail(company.siteAccess?.[0].adminEmail ?? '')
            setCreditLimit(company.requestedCreditLimit ?? '') 
            setCredit(company.preferredPaymentTerms ?? '')
            setAuthorisedName(company.consentArn ?? '')
            setPosition(company.consentPositionTitle ?? '')
            // handleDateChange(company.consentDate ?? '')
            setAuthorisedTermsName(company.termArn ?? '')
            setPositionTerms(company.termPositionTitle ?? '')
            setTradingName(company.tradingName ?? '')
            setCountry(company.country ?? '')
            setCountryRegions(
              Array.isArray(company.statesTerritories)
                ? JSON.stringify(company.statesTerritories)
                : "[]"
            );
            // setUploadedFiles(company.uploadDocuments)
        },
        [],
    )
    const getCompanyDetails = useCallback(
        (companyId: string | number) => {
            dispatch(setLoader(true))
            companyApi.getCompanyDetails(companyId)
                .then(response => {
                    let companyDetails: ICompanyInfo = response.data
                    // console.log(response.data)
                    dispatch(setLoader(false))
                    setEmailNotification(Array.isArray(companyDetails.email_notifications) ? companyDetails.email_notifications : [])
                    setCompanyDetails(companyDetails)
                    setCompanyInfo(companyDetails)

                })
                .catch((err: any) => {
                    handleClose()
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_company_detail'))
                })
        },
        [dispatch, t, setCompanyDetails, handleClose]
    )

    useEffect(() => {
        if (selectedCompanyId) {
            getCompanyDetails(selectedCompanyId)
        }
    }, [selectedCompanyId, getCompanyDetails])

    const handleOnEditClick = () => {
        setIsEditMode(true)
    }

    const handleOnCancelClick = () => {
        setIsEditMode(false)
        if (companyInfo)
            setCompanyDetails(companyInfo)
    }
    const handleEmailNotificationChange = (key: any) => {
        let templist: any[] = emailNotification

        templist.indexOf(key) === -1 ? templist.push(key) : templist.splice(templist.indexOf(key), 1);
        setEmailNotification(templist)
    }
    const handleRatesAccess = () => {
        setRatesAccess(!ratesAccess)
    }




    const emailIsSelected = (key: any) => {
        const data = emailNotification ? emailNotification : []
        return !data.includes(key)
    }
    const handleOnUpdateStatusClick = () => {
        let status: TStatus

        if (companyInfo?.status === INACTIVE) {
            status = ACTIVE
        } else {
            status = INACTIVE
        }

        if (selectedCompanyId) {
            dispatch(setLoader(true))
            companyApi.updateStatus(selectedCompanyId, status)
                .then(() => {
                    dispatch(setLoader(false))
                    getCompanyDetails(selectedCompanyId);
                    handleClose();
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_updating_campaign_status'))
                })

        }
    }

    const getNumberLabelByCountry = (country: string): string => {
        switch (country) {
            case 'australia':
                return 'company_abn'
            case 'singapore':
                return 'company_eun'
            case 'japan':
                return 'company_registration_number'
            default:
                return 'company_tin'
        }
    }

    const handleOnSelectAddress = (mapAddress: MapAddress) => {
        setStreetName([mapAddress.streetNum, mapAddress.route].join(' '))
        setCity(mapAddress.city)
        setPostalCode(mapAddress.postalCode)
        setLatitude(mapAddress.lat)
        setLongitude(mapAddress.long)
    }
    const handleMapAddressSelect = useCallback(
        (mapAddress: MapAddress) => {
          setHasSearchAddress(true)
          setStreetNameAddress([mapAddress.streetNum, mapAddress.route].join(' '))
          setState(mapAddress.state)
          setPostalCode(mapAddress.postalCode)
          setCity(mapAddress.city)
          setLatitude(String(mapAddress.lat))
          setLongitude(String(mapAddress.long))
        },
        [],
      )
      const handleCountryCheck = useCallback(
        (isChecked: boolean, area: string) => {
          if (isChecked) {
            if (!countryRegions.includes(area)) {
              countryRegions.push(area);
              setCountryRegions([...countryRegions]);
            }
          } else {
            setCountryRegions(countryRegions.filter((ins) => ins !== area));
          }
        },
        [countryRegions]
      );
          // Handle date change
    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);
    };
    const handleDateChangeTerms = (event) => {
      const dateValue = event.target.value;
      setSelectedDateTerms(dateValue);
    };
  
    return (
        <>

            <WarningDialog onProceedClick={handleOnProceedDelete} open={isWarningDialogOpen} handleClose={handleOnWarningDialogClose} message={t('warning_about_to_delete_company')} />
            <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}  >
                <DialogTitle>
                    <Typography>
                        {t('company_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers style={{overflowY: 'unset'}} >
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                    >

                   {/* start of code @seema */}

                    <Grid md={4} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid md={4} item>
                     <TextField
                      disabled={!isEditMode}
                      value={TradingName}
                      onChange={(e) => setTradingName(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Trading Name')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>


                  <Grid md={4} item>
                    <TextField
                      disabled={!isEditMode}
                      value={Abn_Acn}
                      onChange={(e) => setAbn_Acn(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('ABN/ACN') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <MapAutocomplete onAddressSelect={handleMapAddressSelect} />
                    </Grid>



                    <Grid xs={12} lg={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('enter_postal_code') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={State}
                      onChange={(e) => setState(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('state')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('country')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                    disabled={!isEditMode}
                      value={companyPostalAddress}
                      onChange={(e) => setCompanyPostalAddress(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Postal Address (if different)')}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                    disabled={!isEditMode}
                      value={Postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Postcode')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                    disabled={!isEditMode}
                      value={PostalState}
                      onChange={(e) => setPostalState(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('state')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={3} item>
                    <TextField
                    disabled={!isEditMode}
                      value={PostalCountry}
                      onChange={(e) => setPostalCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('country')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      name='mobile'
                      variant="outlined"
                      fullWidth
                      label={t('provide_mobile_number') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                    disabled
                      value={email}
                      name='email'
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      fullWidth
                      type="email"
                      label={t('provide_email_address') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}

                    />
                  </Grid>


                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={website}
                      onChange={(e) => {
                        if (e.target.value.length <= 80) {
                          setWebsite(e.target.value);
                        }
                      }}
                      variant="outlined"
                      fullWidth
                      label={t('Website')}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('company_type')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={companyType}
                                    onChange={(e) => setCompanyType(e.target.value as any)}
                                >
                                    <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                    <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                    <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                    <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                    <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('BUSINESS INFORMATION')}
                    </Typography>
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                    disabled={!isEditMode}
                      value={TypeOfBussiness}
                      onChange={(e) => setTypeOfBussiness(e.target.value)}
                      name='TypeOfBussiness'
                      variant="outlined"
                      fullWidth
                      label={t('Type of Bussiness') + '*'}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}

                    />
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                    disabled={!isEditMode}
                      value={ProductsHandled}
                      onChange={(e) => setProductsHandled(e.target.value)}
                      name='ProductsHandled'
                      variant="outlined"
                      fullWidth
                      label={t('Nature of Operations/Products handled')}
                      inputProps={{ maxLength: 200 }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>


                  <Grid md={12} item>
                    <Typography
                      className={classes.paragrapHeading}
                      align="left"
                      variant="body1"
                    >
                      {t(
                        'States/Territories of Operation (please select all that apply)'
                      ) + '*'}
                    </Typography>
                  </Grid>
                  {COUNTRYAREA.map((area, i) => (
                    <Grid key={i} md={i % 2 === 0 ? 3 : 9} item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={countryRegions.includes(area)}
                            onChange={(e) =>
                              handleCountryCheck(e.target.checked, area)
                            }
                            color="primary"
                          />
                        }
                        label={t(area)}
                      />
                    </Grid>
                  ))}
                  <Grid md={6} item>
                    {countryRegions.includes('International') && (
                      <TextField
                        value={serviceRegionsInternational}
                        onChange={(e) =>
                          setServiceRegionsInternational(e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        label={t('Please Specify')}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('ACCOUNT PAYABLE')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(`please add details for account related enquiries`)}
                      </Typography>
                    </Box>
                  </Grid>
                 
                  <Grid item md={4}>
                    <TextField
                    disabled={!isEditMode}
                      value={contactName}
                      onChange={(e) =>
                        setContactName
                        (e.target.value)
                      }                    
                     name='contactName'
                      variant="outlined"
                      fullWidth
                      label="Contact Name *"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                    disabled={!isEditMode}
                      value={contactPhone}
                      onChange={(e) => setContactPhone(e.target.value)}
                      name='contactPhone'
                      variant="outlined"
                      fullWidth
                      label="contact Number *"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      disabled
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      name='contactEmail'
                      variant="outlined"
                      fullWidth
                      type="email"
                      label="contact Email *"
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={1}
                    style={{ textAlign: 'end', marginTop: '8px' }}
                  ></Grid>




<Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('MOVEIT4U SITE ACCESS')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(
                          `Please nominate an admin email address that will be used to manage your account within our software application.`
                        )}
                        <br />
                        {t(
                          `This admin will have the ability to create, edit, and manage additional users within your company profile.`
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                    disabled={true}
                      value={adminName}
                      onChange={(e) => setAdminName(e.target.value)}
                      name='adminName'
                      variant="outlined"
                      fullWidth
                      label={t(' Admin Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                    disabled={true}
                      value={adminPhone}
                      onChange={(e) => setAdminPhone(e.target.value)}
                      name='adminPhone'
                      variant="outlined"
                      fullWidth
                      label={t('Admin Phone') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                     disabled={true}
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                      name='adminEmail'
                      variant="outlined"
                      fullWidth
                      type="email"
                      label={t('Admin Email') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={12} item>
                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(
                          `Please note: Logins and software access details will be provided once your account application has been approved.`
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={6} item>
                    {/* EMPTY */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('CREDIT TERMS')}
                    </Typography>
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={operationscreditlimit}
                      name='operationscreditlimit'
                      onChange={(e) => setCreditLimit(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Requested Credit Limit (AUD)') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <Box display="flex" alignItems="center">
                      <TextField
                      disabled={!isEditMode}
                        value={operationscredit}
                        onChange={(e) => setCredit(e.target.value)}
                        name='operationscredit'
                        variant="outlined"
                        fullWidth
                        label={t('Preferred Payment Terms') + '*'}
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: '8px', fontWeight: 'bold' }}
                      >
                        {t('days')}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('CREDIT CHECK CONSENT')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(`add text from scoping here.`)}
                        <br />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={authorisedName}
                      name='authorisedName'
                      onChange={(e) => setAuthorisedName(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Authorised Representative Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                    disabled={!isEditMode}
                      value={position}
                      name='position'
                      onChange={(e) => setPosition(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Position/Title') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <FileInput
                      onChange={handleFileUploadConsentSignature}
                      label={t('Upload Signature') + '*'}
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid md={6} item>
                  <CustomDatePicker
                      selectedDate={selectedDate}
                      handleDateChange={handleDateChange}
                      readOnly={false}
                      placeholder="Select Date"
                      name="datePicker"
                      error={error}
                    />
                  </Grid>

                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  {/* <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body2"
                    >
                      {t('UPLOAD DOCUMENT')}
                    </Typography>
                    <FileInput
                    disabled={!isEditMode}
                      onChange={handleFileUploadCompanyRegister}
                      label={t('Upload Docs') + '*'}
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid> */}



<Grid md={12} item>


<div className={classess.line}></div>
 <Typography
     variant="body1"
     gutterBottom
 >
     UPLOAD DOCUMENTS
 </Typography>
 <div className={classess.flexColumn}>

     <div {...getRootProps({ className: 'dropzone' })} className={classess.draganddrop}>
         <input {...getInputProps()} />
         {file ? (
             <p>Selected file: {file.name}</p>
         ) : (
             <p>Drag 'n' drop to upload, or browse documents</p>
         )}
     </div>
     <SelectorComp
         label="Document Type"
         value={docType}
         onChange={handleDocTypeChange}
         options={DOCS}
         name="document"
     />
     <div className={classess.flexWrapperIcon} onClick={handleAddDocument}><AddIcon /><div>Add Documents</div></div>
     <div className={classess.previewTitle}>Attachments</div>
     <div className={classess.previewContainer}>
         {Object.entries(uploadedFiles).map(([type, uploadedFile]) => (
             uploadedFile && (
                 <div key={type} className={classess.previewCard}>
                     <div className={classess.cardContent}>
                         <div className={classess.cardItemFileName}>
                             <strong>File:</strong> {uploadedFile.name}
                         </div>
                         <div className={classess.cardItemType}>
                             <strong>Type:</strong> {type}
                         </div>
                     </div>
                     <button
                         onClick={() => handleDeleteFile(type as DocType)}
                         className={classess.deleteButton}
                     >
                         Delete
                     </button>
                 </div>
             )
         ))}
     </div>
 </div>
 <div className={classess.line}></div>
</Grid>










                  <Grid className={classes.gap} md={12} item>
                    {/* GAP */}
                  </Grid>

                  <Grid md={12} item>
                    <Typography
                      className={classes.title}
                      align="left"
                      variant="body1"
                    >
                      {t('TERMS AND CONDITIONS')}
                    </Typography>

                    <Box>
                      <Typography className={classes.paragrapHeading2}>
                        {t(
                          `Payment Terms and Credit Limit (add text from scoping doc here).`
                        )}
                        <br />
                        {t(`Payment Terms and Credit limit.`)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid md={6} item>
                    <TextField
                    disabled={!isEditMode}
                      value={authorisedTermsName}
                      onChange={(e) => setAuthorisedTermsName(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Authorised Representative Name') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <TextField
                    disabled={!isEditMode}
                      value={positionTerms}
                      onChange={(e) => setPositionTerms(e.target.value)}
                      variant="outlined"
                      fullWidth
                      label={t('Position/Title') + '*'}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>

                  <Grid md={6} item>
                    <FileInput
                    disabled={!isEditMode}
                      onChange={handleFileUploadTermsCompanySignature}
                      label={t('Upload Signature') + '*'}
                      multiple={false}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid md={6} item>
                    <CustomDatePicker
                      selectedDate={selectedDateTerms}
                      handleDateChange={handleDateChangeTerms}
                      readOnly={false}
                      placeholder="Select Date"
                      name="datePicker"
                      error={error}
                    />
                  </Grid>





                     {/* end of code @seema                       */}
                        <Grid md={6} item>
                            {/* <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value as any)}
                                >
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Grid>
                        {/* <Grid md={6} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('company_type')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={companyType}
                                    onChange={(e) => setCompanyType(e.target.value as any)}
                                >
                                    <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                    <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                    <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                    <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                    <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyUen}
                                onChange={(e) => setCompanyUen(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t(getNumberLabelByCountry(country))}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyEmail}
                                onChange={(e) => setCompanyEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyMobile}
                                onChange={(e) => setCompanyMobile(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_mobile')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid> */}

                        {/* {
                            isEditMode
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={newPasswod}
                                            onChange={(e) => setNewPasswod(e.target.value)}
                                            type="password"
                                            variant="outlined"
                                            fullWidth
                                            label={t('new_password')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            variant="outlined"
                                            type="password"
                                            fullWidth
                                            label={t('confirm_password')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                                : null
                        } */}

                        {/* <Grid md={12} item>
                            <Typography className={classes.title} align="left" variant="body1">
                                {t('address_details')}
                            </Typography>
                        </Grid>
                        <Grid md={12} item>
                            <MapAutocomplete onAddressSelect={handleOnSelectAddress} />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={!isEditMode}
                                value={streetName}
                                onChange={(e) => setStreetName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('street_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('city')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('postal_code')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid> */}
                        <Grid md={12} item>
                            <Typography className={classes.title} align="left" variant="body1">
                                {t('please_confirm_your_insurance_coverage')}
                            </Typography>
                        </Grid>
                        {INSURANCES.map((insurance, i) => (
                            <Grid md={(i % 2) === 0 ? 3 : 9} item>
                                <FormControlLabel
                                    control={<Checkbox disabled={!isEditMode} checked={insurances.includes(insurance)} onChange={(e) => handleInsuranceCheck(e.target.checked, insurance)} color="primary" />}
                                    label={t(insurance)}
                                />
                            </Grid>
                        ))}
                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        {/* <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountPhone}
                                onChange={(e) => setAccountPhone(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_phone')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountEmail}
                                onChange={(e) => setAccountEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid> */}


                        {/* <Grid md={6} item>

                        </Grid> */}



                        {/* <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsName}
                                onChange={(e) => setOperationsName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsPhone}
                                onChange={(e) => setOperationsPhone(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_phone')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsEmail}
                                onChange={(e) => setOperationsEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid> */}



                        {
                            companyType === TRANSPORT_COMPANY
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitingTime}
                                            onChange={(e) => setWaitingTime(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('waiting_time')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitingTimeCharge}
                                            onChange={(e) => setWaitingTimeCharge(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('waiting_time_charge')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                </>
                                : null

                        }
                        <Grid md={6} item>
                            {/* EMPTY */}
                        </Grid>
                        {
                            [NVOCC, FREIGHT_FORWARED].includes(companyType)
                                ? <>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                    <Grid md={12} item>
                                        <Typography className={classes.title} align="left" variant="body1">
                                            {t('importer_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerName}
                                            onChange={e => setImporterName(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_name')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerPhone}
                                            onChange={e => setImporterPhone(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_phone')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DialpadIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerEmail}
                                            onChange={e => setImporterEmail(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_email')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <AlternateEmailIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        {/* EMPTY */}
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                    <Grid md={12} item>
                                        <Typography className={classes.title} align="left" variant="body1">
                                            {t('exporter_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterName}
                                            onChange={e => setExporterName(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_name')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterPhone}
                                            onChange={e => setExporterPhone(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_phone')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DialpadIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterEmail}
                                            onChange={e => setExporterEmail(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_email')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <AlternateEmailIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        {/* EMPTY */}
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                </>
                                : null
                        }
                        <Grid lg={12} item>

                            <Grid lg={12} container>
                                <Grid lg={12} item>
                                    <Typography
                                        className={classes.title}
                                        align="left"
                                        variant="body1"
                                    >
                                        Notification Settings
                                    </Typography>
                                </Grid>
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={'Job Completion'}
                                    keyValue={'job_completion'}
                                    selected={emailIsSelected('job_completion')}
                                    onChange={handleEmailNotificationChange}
                                />
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={'Scheduled Delivery'}
                                    keyValue={'scheduled_delivery'}
                                    selected={emailIsSelected('scheduled_delivery')}
                                    onChange={handleEmailNotificationChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid lg={12} item>

                            <Grid lg={12} container>
                                <Grid lg={12} item>
                                    <Typography
                                        className={classes.title}
                                        align="left"
                                        variant="body1"
                                    >{t('access_management')}
                                    </Typography>
                                </Grid>
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={t('rates_management')}
                                    keyValue={'rates_management'}
                                    selected={ratesAccess}
                                    onChange={handleRatesAccess}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item md={3}>
                            {
                                !isEditMode
                                    ? <Grid item md={6}>
                                        <Button onClick={() => handleOnDeleteClick()} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                            {t('delete')}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </Grid>

                        <Grid item md={9}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                <Grid item md={3}>
                                    <Button onClick={() => handlePasswordOpen()} startIcon={<ClearIcon />} variant="contained" fullWidth>Change Password</Button>
                                </Grid>
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnUpdateStatusClick()} className={classes.updatebtn} startIcon={companyInfo?.status === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(companyInfo?.status === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button startIcon={<EditIcon />} fullWidth onClick={() => handleOnEditClick()} className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnUpdateClick()} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnCancelClick()} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>



                    </Grid>
                </DialogActions>
                <ChangePassword
                    open={showChangePassword}
                    handleClose={handlePasswordClose}
                    onSubmit={handleChangePasswordSubmit}
                />
            </Dialog>
        </>

    )
}


export default ViewCompanyDetailsDialog