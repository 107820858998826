import ModalWrapper from '../wrappers/ModalWrapper';
import { Box,Typography } from '@material-ui/core';
import ContainersTable from '../tables/ContainersTable';
import OthersTable from '../tables/OthersTable';

interface ViewJobModalProps {
    open: boolean;
    close: () => void;
    assignedData?: any
}

const ViewJobModal = ({ open, close, assignedData }: ViewJobModalProps) => {

    const handleClose = () => {
        close();
    };

    if (!assignedData) {
        return (
            <ModalWrapper open={open} onClose={handleClose} heading="View Job">
                <Box padding={2}>
                    <Typography variant="body1">No data available to display.</Typography>
                </Box>
            </ModalWrapper>
        );
    }
    const isContainer = assignedData?.movement_type === "containers"

    return (
        <ModalWrapper open={open} onClose={handleClose} heading="View Job">
            {isContainer ? <ContainersTable data={assignedData} /> : <OthersTable data={assignedData} />}
        </ModalWrapper>
    );
};

export default ViewJobModal;
