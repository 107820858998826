import { s3 } from './awsConfig';

const MAX_FILE_SIZE_MB = 20; // Maximum file size in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

export const s3UploadMethod = async (
	selectedFiles: File[],
	folderName: string,
	onSuccess: (locations: string[]) => void,
	onError: (error: any) => void
) => {
	// Check file sizes and filter valid files
	const validFiles = selectedFiles.filter(file => {
		console.log(`Checking file: ${file.name}, size: ${file.size} bytes`);
		return file.size <= MAX_FILE_SIZE_BYTES;
	});

	const invalidFiles = selectedFiles.filter(file => file.size > MAX_FILE_SIZE_BYTES);

	if (invalidFiles.length > 0) {
		console.error('Files exceeding the size limit:', invalidFiles);
		onError(new Error('Some files exceed the maximum allowed size of 20 MB.'));
		return; // Stop further processing if any file is too large
	}

	// Create upload promises for each valid file
	const uploadPromises = validFiles.map(file => {
		const fileName = file.name;
		const key = `${folderName}/${fileName}`;
		const params = {
			Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
			// Bucket: 'moveit4u-file',
            Key: key,
			Body: file,
		};
		return s3
			.upload(params)
			.promise()
			.then(({ Location }) => Location)
			.catch(error => {
				console.error('Upload error:', error);
				onError(error);
				throw error;
			});
	});

	try {
		const locations = await Promise.all(uploadPromises);
		onSuccess(locations);
		return locations;
	} catch (error) {
		console.error('Overall error:', error);
		onError(error);
	}
};
