// CountrySelect.js
import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid, TextField, InputAdornment } from "@material-ui/core";
import { COUNTRIES, ADDRESS_GROUPS, USER_ROLES, AU_STATES } from "../../../Types/Constants";
const { PHILIPPINES, AUSTRALIA, JAPAN, SINGAPORE, NEW_ZEALAND } = COUNTRIES

const useStyles = makeStyles(() => ({
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    formControl: {
        minWidth: '100%',
    }
}))

const CustomerSelect = ({ value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" >
            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_customer')}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('select_your_customer')}
                disabled={isPreview && !isEditable}

            >
                <MenuItem value="">
                    <em>Select {t('customer')}</em>
                </MenuItem>
                <MenuItem >Devtrustcustomer</MenuItem>
                <MenuItem>NewCustomer</MenuItem>
            </Select>
        </FormControl>
    );
};


export const TimeslotRequiredSelect = ({ value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" >
            <InputLabel style={{ fontWeight: 'bold' }}>{t('Timeslot Required')}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('select_your_Timeslot Required')}
                disabled={isPreview && !isEditable}

            >
                <MenuItem value="">
                    <em>Select {t('Timeslot Required')}</em>
                </MenuItem>
                <MenuItem >FALSE</MenuItem>
                <MenuItem>TRUE</MenuItem>
            </Select>
        </FormControl>
    );
};
export default CustomerSelect;
