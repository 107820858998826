export const Mi4uRefNumFunc = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); 
    let day = String(date.getDate()).padStart(2, '0');
    let newDate = `${year}${month}${day}`;

    let text = "";
    const possible = "0123456789";
    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return `MI4U-${newDate}-${text}`;
}


export const getTodayDate = () => {
    const today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    const yyyy = today.getFullYear();
    return new Date(`${mm}/${dd}/${yyyy}`);
  };

 export function formatDateToMMDDYYYY(dateString: string): string {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return 'Invalid date';
    }
  
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }


  export const checkIsEmpty=(val:string)=>{
    return val===""?null:val
  }

  export const checkValue=(val:any)=>{
    return val?val:"NA"
  }