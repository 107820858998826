import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inputPadding: {
      '& .MuiOutlinedInput-input': {
        padding: '8px 16px !important',
      },
      '& .MuiSelect-outlined': {
        paddingRight: '32px !important',
      },
       
    },
  }),
);

interface JobStatusDropdownProps {
  options: { id: string | number; value: string | number; label: string }[];
  selectedValue: string | number;
  onChange: (selectedId: string | number) => void;
  placeholder?: string;
  helperText?: string;
  isError?: boolean;
  isDisabled?: boolean;
}

const JobStatusDropdown: React.FC<JobStatusDropdownProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = '',
  helperText = '',
  isError = false,
  isDisabled = false,
}) => {
  const classes = useStyles();

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedOption = options.find((option) => option.id === event.target.value as string | number);
    if (selectedOption) {
      onChange(selectedOption.id);
    }
  };

  return (
    <FormControl
      className={classes.formControl}
      disabled={isDisabled}
      error={isError}
    >
      <Select value={selectedValue} onChange={handleSelectChange} displayEmpty variant='outlined' className={classes.inputPadding}>
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default JobStatusDropdown;
