
import ModalWrapper from '../wrappers/ModalWrapper'
import { toast } from 'react-toastify';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';
import { Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { validationPDFJobSchema } from '../lib/validationSchema';
import InputBox from '../ui/InputBox';
import { useState } from 'react';

interface GenerateJobPdfProps {
  open: boolean;
  close: () => void;
  fetchJobs: () => void;
  assignedData?: any
}

const storage = new WebStorage();

const GenerateJobPdf = ({ open, close, assignedData, fetchJobs }: GenerateJobPdfProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    email: "",
  };

  const handlePdfGenerate = async (values: any, { resetForm }: any) => {
    setLoading(true)
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "jobId": assignedData?.id,
        "email": values?.email
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/sendJob/mail", requestOptions);
      const result = await response.json();

      if (result.status_code === 201 && result.success === true) {
        toast.success("Job PDF emailed successfully");
        fetchJobs()
        handleClose()
        resetForm()
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    close();
  };

  return (
    <>
      <ModalWrapper
        open={open}
        onClose={handleClose}
        heading="Generate PDF of the Job"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handlePdfGenerate}
          enableReinitialize={true}
          validationSchema={validationPDFJobSchema}
        >
          {({ handleChange, values, errors, touched }) => (
            <Form >

              <InputBox
                value={values.email}
                onChange={handleChange}
                name={`email`}
                type="text"
                label="Email"
                disabled={false}
                error={errors.email && touched.email ? errors.email : null}

              />
              <Button
                type="submit"
                fullWidth
                color="secondary"
                variant="contained"
                style={{ margin: '20px 0' }}
                disabled={loading}

              >
                {loading ? "Generating..." : "Generate"}
              </Button>
            </Form>
          )}

        </Formik>
      </ModalWrapper>

    </>
  )
}

export default GenerateJobPdf