import AWS from 'aws-sdk';

AWS.config.update({
	region: process.env.REACT_APP_AWS_REGION!,
    // region: 'ap-southeast-2',
	credentials: {
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,


        // accessKeyId: 'AKIA4RUAJYFBNQFYPM5I',
		// secretAccessKey: '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',
	},
});

export const s3 = new AWS.S3();
