import React, { useRef } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Box
} from "@material-ui/core";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import { UsersApi } from "../../../../Api/Users/Users";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import CustomerRegister from '../../../Landing/CustomerRegister/CustomerRegister'
import {
  COUNTRIES,
  USER_PRIVILEGES,
  USER_ROLES,
} from "../../../../Types/Constants";
import { AppState } from "../../../../Redux";
import isEmail from "validator/lib/isEmail";

const { AUSTRALIA } = COUNTRIES;

const { ADMIN, ADMIN_USER,COMPANY_USER, COMPANY, SUPER_ADMIN, DRIVER_MANAGER, OTHER, CUSTOMER, ADMIN_RESTRICTED, ADMIN_OPERATIONS } =
  USER_ROLES;

const {
  CONFIGURATIONS,
  DRIVER_MANAGEMENT,
  TRANSPORT_MANAGEMENT,
} = USER_PRIVILEGES;

const useStyles = makeStyles(() => ({
  btnCreate: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  hidden: {
    display: 'none'
  }
}));

const userApi = new UsersApi();


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function AddUser() {
  const classes = useStyles();
  const user = useSelector((state: AppState) => state.user);
  const isUserSuperAdmin = user.response?.type === SUPER_ADMIN;
  const isUserAdminUser = user.response?.type === ADMIN_USER;
  const isUserCustomer = user.response?.type === CUSTOMER;
  const isUserCompany = user.response?.type === COMPANY;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const [t] = useTranslation("AddUser");
  const [showPass, setShowPass] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isConfiguration, setIsConfiguration] = useState(false);
  const [isDriverManagement, setIsDriverManagement] = useState(false);
  // const [isSeaFreightManagement, setIsSeaFreightManagement] = useState(false);
  const [isTransportManagement, setIsTransportManagement] = useState(false);
  const [isOperations, setIsOperations] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [company, setCompany] = useState('');
  const [Abn_Acn, setAbn_Acn] = useState('');
  const [job_title, setJob_Title] = useState('');
  const [phone, setPhone] = useState('');
  const [userPermission, setUserPermission] = useState('isOperations');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [social_links, setSocial_Links] = useState('')
  const [privacy_settings, setPrivacy_Settings] = useState('')
  const [upload_docs, setUpload_Docs] = useState(null)
  const [communication_preferences, setCommunication_preferences] = useState('')
  const [country, setCountry] = useState<string | undefined>(
    isUserSuperAdmin ? AUSTRALIA : user.response?.company?.country
  );

  const isAdmin = isUserSuperAdmin || isUserAdminUser
  const [userType, setUserType] = useState(DRIVER_MANAGER);

  const clearForm = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setPassword('');
    setPasswordConfirm('');
    setCountry('');
    setAbn_Acn('');
    setCompany('');
    setJob_Title('');
    setAddress('');
    setWebsite('');
    setSocial_Links('');
    setCommunication_preferences('');
    setPrivacy_Settings('');
    setPhone('');
    setUpload_Docs(null);
    setUserPermission(null);
    setIsConfiguration(false);
    setIsTransportManagement(false);
    setIsDriverManagement(false);
  }

  const handleOnCreateClick = () => {
    let type: string = "";
    let privileges: string[] = [];

    if (firstName === "") {
      toast.warning(t("firstname_not_empty"));
      return;
    }

    // if (lastName === "") {
    //   toast.warning(t("lastname_not_empty"));
    //   return;
    // }

    if (email === "") {
      toast.warning(t("email_not_empty"));
      return;
    } else if (!isEmail(email)) {
      toast.warning(t("email_invalid_format"));
      return;
    }

    if (password === "" ) {
      toast.warning(t("password_not_null"));
      return;
    } else if (password.length < 6) {
      toast.warning(t("invalid_password_length"));
      return;
    } else if (passwordConfirm == '') {
      toast.warning(t("Confirm password must not be empty"));
      return;
    }else if (passwordConfirm !== "") {
      if (password !== passwordConfirm) {
        toast.warning(t("password_did_not_match"));
        return;
      }
    }

  

    if (isConfiguration) privileges.push(CONFIGURATIONS);
    if (isTransportManagement) privileges.push(TRANSPORT_MANAGEMENT);
    // if (isSeaFreightManagement) privileges.push(SEA_FREIGHT_MANAGEMENT);
    if (isDriverManagement) privileges.push(DRIVER_MANAGEMENT);

    if ([ADMIN, SUPER_ADMIN].includes(user.response?.type || "")) {
      // console.log(userPermission, 'userPermission');l
      type = userPermission ==='isRestricted'? ADMIN_RESTRICTED : ADMIN_OPERATIONS;
    } else if (user.response?.type === COMPANY) {
      type = "COMPANY USER";
    }
    const formData = new FormData();

    if (upload_docs) {
      formData.append('upload_docs', upload_docs);


    }

    formData.append('emailAddress', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('password', password);
    formData.append('password_confirm', passwordConfirm);
    formData.append('privileges', privileges.join(','));
    formData.append('type', type);
    formData.append('country', country);
    formData.append('company', company);
    formData.append('abn_acn', Abn_Acn);
    formData.append('jobtitle', job_title);
    formData.append('address', address);
    formData.append('Website', website);
    formData.append('social_links', social_links);
    formData.append('communication_preferences', communication_preferences);
    formData.append('privacy_settings', privacy_settings);
    formData.append('mobile', phone);
    // formData.append('user_role', [userPermission].join(','));


    dispatch(setLoader(true));
    userApi
      .add(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        dispatch(setLoader(false));
        toast.success(t("success_adding_user"));
        clearForm();
        type = '';
        privileges = [];
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      })
      .catch((err: any) => {
        dispatch(setLoader(false));
        console.log("err", err);
        toast.error(t(err.response?.data || "error_adding_user"));
      });
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUpload_Docs(file);

  };

  return (
    <Grid container spacing={5} style={{ height: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab label="User" {...tabProps(0)} />
        <Tab
          label="Customer"
          className={!isAdmin && !isUserCompany ? classes.hidden : ''}
          {...tabProps(1)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={5}>
          {/* {isUserCompany ? (
            <Grid md={12} item>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel style={{ fontWeight: "bold" }}>
                  {t("select_user_type")}
                </InputLabel>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value as string)}
                >
                  <MenuItem value={DRIVER_MANAGER}>{t(DRIVER_MANAGER)}</MenuItem>
                  <MenuItem value={COMPANY_USER}>{t(COMPANY_USER)}</MenuItem>
                  <MenuItem value={OTHER}>{t(OTHER)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null} */}
         
          <Grid item md={6}>
            <TextField
              value={firstName}
              onChange={(e) => { if (e.target.value.length <= 80) { setFirstName(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t("enter_first_name") + "*"}
             
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={lastName}
              onChange={(e) => { if (e.target.value.length <= 80) { setLastName(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t("enter_last_name")}
             
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={email}
              onChange={(e) => { if (e.target.value.length <= 80) { setEmail(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t("provide_email_address") + "*"}
             
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid md={6} item>
            <TextField
              value={website}
              onChange={(e) => { if (e.target.value.length <= 80) { setWebsite(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t('Website')}
            
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              value={password}
              onChange={(e) => { if (e.target.value.length <= 25) { setPassword(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t("enter_password") + "*"}
              type={showPass ? "text" : "password"}
            
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              value={passwordConfirm}
              onChange={(e) => { if (e.target.value.length <= 25) { setPasswordConfirm(e.target.value) } }}
              variant="outlined"
              fullWidth
              label={t("confirm_password") + "*"}
              type={showPass ? "text" : "password"}
             
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              value={company}
              onChange={(e) => { if (e.target.value.length <= 25) { setCompany(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t('company')}
              
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              value={Abn_Acn}
              onChange={(e) => { if (e.target.value.length <= 11) { setAbn_Acn(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t('ABN/ACN')}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid md={4} item>
            <TextField
              value={phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {
                  setPhone(value);
                }
              }}
              type="number"
              variant="outlined"
              fullWidth
              label={t('Phone')}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid md={12} item>
            <TextField
              value={address}
              onChange={(e) => { if (e.target.value.length <= 100) { setAddress(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t('Address')}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>


          <Grid item md={4} xs={12}>
            <TextField
              value={job_title}
              onChange={(e) => { if (e.target.value.length <= 80) { setJob_Title(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t("Job Title")}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              value={communication_preferences}
              onChange={(e) => { if (e.target.value.length <= 80) { setCommunication_preferences(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t("Communication Preferences")}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              value={privacy_settings}
              onChange={(e) => { if (e.target.value.length <= 80) { setPrivacy_Settings(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t("Privacy Settings")}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid md={6} item>
            <TextField
              value={social_links}
              onChange={(e) => { if (e.target.value.length <= 80) { setSocial_Links(e.target.value) } }}

              variant="outlined"
              fullWidth
              label={t('Social Media Links')}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid md={6} item>
            <TextField
            inputRef={fileInputRef}
              type="file"
              onChange={handleImageChange}
              variant="outlined"
              fullWidth


            />
          </Grid>
         {isUserSuperAdmin &&  isUserAdminUser && <>
           <Grid md={12} item>
             <Typography align="left" variant="body1">
               {t('User Permission')}
             </Typography>
           </Grid>
           <Grid md={3} item>
             <FormControlLabel
               control={
                 <Checkbox
                 color="primary"
                 checked={userPermission == 'isRestricted'}
                 onChange={(e) => {
                   const permissionValue = e.target.checked ? 'isRestricted' : '';
                   setUserPermission(permissionValue);
                   setIsRestricted(e.target.checked);
                 }}
               />
               }
               label={t('Restricted')}
             />
           </Grid>
           
           <Grid md={3} item>
             <FormControlLabel
               control={
                 <Checkbox
                 color="primary"
                 checked={userPermission == 'isOperations'}
                 onChange={(e) => { 
                   const permissionValue = e.target.checked ? 'isOperations' : '';
                   setUserPermission(permissionValue);
                   setIsOperations(e.target.checked);
                 }}
               />
               }
               label={t('Operations')}
             />
           </Grid>
         </>
         }

          {isUserSuperAdmin || userType === OTHER ? (
            <>
              <Grid md={12} item>
                <Typography align="left" variant="body1">
                  {t("set_privileges")}
                </Typography>
              </Grid>
              <Grid md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isTransportManagement}
                      onChange={(e) => setIsTransportManagement(e.target.checked)}
                    />
                  }
                  label={t("transport_management")}
                />
              </Grid>
            
              <Grid md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isConfiguration}
                      onChange={(e) => setIsConfiguration(e.target.checked)}
                    />
                  }
                  label={t("configuration")}
                />
              </Grid>
              <Grid md={9} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isDriverManagement}
                      onChange={(e) => setIsDriverManagement(e.target.checked)}
                    />
                  }
                  label={t("driver_management")}
                />
              </Grid>
            </>
          ) : null}

          <Grid md={6} item>
            <Button
              startIcon={<DoneIcon />}
              className={classes.btnCreate}
              variant="contained"
              onClick={handleOnCreateClick}
              fullWidth
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CustomerRegister />
      </TabPanel>
    </Grid>
  );
}

export default AddUser;
