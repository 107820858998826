
import React from "react";
const TermsAndConditions = () => {
  return (
    <div>
    <div style={{   fontWeight: 'normal', fontSize: '12px',}}>
      <section>
        <p>
          Payment Terms and Credit Limit: The requested payment terms and credit limit requested by the customer are
          indicative only  and subject to approval by Moveit4u. Following the credit assessment,<br/> Moveit4u will assign
          approved  payment terms and credit limit, which may differ from what was requested. The customer agrees to
          adhere to the approved payment terms and credit limit as determined   <br/> by Moveit4u.
          <br/>
          Suspension of Services: Moveit4u reserves the right to suspend or withdraw credit facilities if the terms are
          not adhered to or if the customer’s financial status changes.
          <br/>
          Interest on Overdue Accounts: Interest may be charged on overdue amounts at a rate permissible under Australian
          legislation.<br/>
          Updates to Business Information:The customer must notify Moveit4u of any company changes to ownership, structure, or key
          contacts immediately.<br/>
          Right to Review: Moveit4u reserves the right to review the credit limit and payment terms periodically and
          may make changes based on the customer’s creditworthiness or business performance.<br/>
          By signing the below, I/We agree to the above Terms and Conditions, and confirm that the
          information provided in this application is accurate and complete.
        </p>
      </section>
    </div>
    </div>
  );
};
export default TermsAndConditions;