export const JOBS_WILDCARDS_KEYS = {
  SHOW_TYPE: 'showType',
};

export const PENDING = 'pending';

export const POSTED = 'posted';

export const MYJOB = 'myjob';

export const ALL = 'all';

export const AVAILABLE = 'available';

export const ASSIGNED = 'assigned';

export const IN_PROGRESS = 'in_progress';

export const MY_IN_PROGRESS = 'my_in_progress';

export const PICKED_UP = 'picked_up';

export const DELIVERED_JOBS = 'delivered_jobs';

export const COMPLETED_JOBS = 'completed_jobs';

export const BIDS = 'bids';

export const COMPLETED = "completed"

export const MY_BIDS = 'my_bids';

export const BIDS_TO_CONFIRM = 'bids_to_confirm';

export const JOB_REPORT = 'job_report';

export const NO_POD_DOCUMENTS = 'no_pod_documents';

export const MAPS_DATA = 'maps_data';

export const SCHEDULED_COLLECTIONS = "scheduled_collections"

export const SCHEDULED_DELIVERIES = "scheduled_deliveries"

// export const SCHEDULED_COLLECTIONS = "scheduled_collections"

export const CUSTOMER_IN_PROGRESS = 'customer_in_progress';


