import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import CreateNewJob from '../Jobs/CreateNewJob/CreateNewJob';
import JobReport from '../Jobs/JobReport/JobReport';
import JobReportMileage from '../Jobs/JobReportMileage/JobReportMileage';
import { JOBS_WILDCARDS_KEYS } from '../Jobs/Jobs.constans';
import { QUOTES_WILDCARDS_KEYS } from '../Quotes/Quotes.constants';

import ShowJobs from '../Jobs/ShowJobs/ShowJobs';
import ShowJobsLazy from '../Jobs/ShowJobs/ShowJobsLazy';
import ShowQuotesLazy from '../Quotes/ShowQuotes/ShowQuotesLazy'

import MapViewV2 from '../MapsV2/Maps';
import CreateJobV2 from '../../../ComponentsV2/pages/jobs/CreateJobV2';
import JobsToBeAssignedV2 from '../../../ComponentsV2/pages/jobs/JobsToBeAssignedV2';
import AllJobsV2 from '../../../ComponentsV2/pages/jobs/AllJobsV2';
import JobsToBeAssignedToCarrier from '../../../ComponentsV2/pages/jobs/JobsToBeAssignedToCarrier';
import EditJobV2 from '../../../ComponentsV2/pages/jobs/EditJobV2';
import CompletedJobsV2 from '../../../ComponentsV2/pages/jobs/CompletedJobsV2';
import ScheduleCollectionV2 from '../../../ComponentsV2/pages/jobs/ScheduleCollectionV2';
import ScheduleDeliveriesV2 from '../../../ComponentsV2/pages/jobs/ScheduleDeliveriesV2';

function TransportManagement() {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      {/* /home/transport/job/create */}
      <Route path={match.path + routes.home.jobs.create_new_job}>
        {/* <CreateNewJob type="transport" mode="create" /> */}
        <CreateJobV2 type="transport" mode="create" />

      </Route>
      {/* /home/transport/create/job/assigned */}
      <Route path={match.path + routes.home.jobs.create_new_assigned_job}>
        <CreateNewJob type="assigned-transport" mode="create" />
      </Route>
      {/* /home/transport/job_report */}
      <Route path={match.path + routes.home.jobs.job_report}>
        <JobReport type="transport" />
      </Route>
      {/* /home/transport/completed_jobs_mileage */}
      <Route path={match.path + routes.home.jobs.job_report_mileage}>
        <JobReportMileage type="transport" />
      </Route>

      <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          '/edit/:id'
        }
      >
        <EditJobV2 />
      </Route>


      <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/pending`
        }>
        <JobsToBeAssignedV2 />
      </Route>
      {/* /home/transport/show/jobs/:showType */}
      <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/all`
        }
      >
        {/* <ShowJobsLazy type="transport" pageType={''} /> */}
        <AllJobsV2 />
      </Route>
      {/* /home/transport/map-view */}
      <Route path={match.path + routes.home.overview}>
        <MapViewV2 type="transport" />
      </Route>

      <Route path={
        match.path +
        routes.home.jobs.show_jobs.root +
        `/myjob`
      }>
        <JobsToBeAssignedToCarrier />
      </Route>

      {/* /home/transport/completed-jobs */}
      <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/completed_jobs`
        }
      >
        <CompletedJobsV2 />
      </Route>

        {/* /home/transport/scheduled_collections */}
        <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/scheduled_collections`
        }
      >
        <ScheduleCollectionV2 />
      </Route>

        {/* /home/transport/scheduled_deliveries */}
        <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/scheduled_deliveries`
        }
      >
        <ScheduleDeliveriesV2 />
      </Route>

    </React.Fragment>
  );
}

export default TransportManagement;
