import { useState } from 'react';
import ModalWrapper from '../wrappers/ModalWrapper';
import { Button, makeStyles, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { WebStorage } from '../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';

interface UploadPodModalProps {
    open: boolean;
    close: () => void;
    fetchJobs: () => void;
    assignedData?: any;
}

const storage = new WebStorage();

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        margin: '20px 0',
    },
    dropzone: {
        border: '2px dashed #e0e0e0',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    dropzoneActive: {
        borderColor: theme.palette.primary.main,
        backgroundColor: '#f0f8ff',
    },
    filePreview: {
        marginTop: '10px',
        textAlign: 'left',
    },
    fileItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    submitButton: {
        marginTop: '20px',
    },
}));

const UploadPodModal = ({ open, close, assignedData, fetchJobs }: UploadPodModalProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const onDrop = (acceptedFiles: File[]) => {
        setFiles((prevFiles) => {
            const existingFileNames = new Set(prevFiles.map(file => file.name));
            const newFiles = acceptedFiles.filter(file => !existingFileNames.has(file.name));
            return [...prevFiles, ...newFiles];
        });
        setIsDragging(false);
    };

    const onDragEnter = () => setIsDragging(true);
    const onDragLeave = () => setIsDragging(false);

    const removeFile = (fileToRemove: File) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter,
        onDragLeave,
        // accept: {
        //     'image/*': ['.jpeg', '.png', '.jpg', '.pdf'],
        // },
        // maxFiles: 5,
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (files.length === 0) {
            toast.error("Please upload at least one file");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("job_id", assignedData?.id);
        files.forEach((file) => formData.append("files", file));

        try {
            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/uploadPOD", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
                },
                body: formData,
            });

            const result = await response.json();

            if (result.status_code === 201 && result.success) {
                toast.success("POD uploaded successfully");
                fetchJobs();
                handleClose();
            } else {
                toast.error(result.message || "Failed to upload POD");
            }
        } catch (error) {
            toast.error("Error uploading POD: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        if (!loading) {
            setFiles([]);
            close();
        }
    };

    return (
        <ModalWrapper open={open} onClose={handleClose} heading="Upload POD">
            <form onSubmit={handleSubmit}>
                <div 
                {...getRootProps({ className: `${classes.dropzone} ${isDragging ? classes.dropzoneActive : ''}` })}
                >
                    <input {...getInputProps()} />
                    <p>Drag & drop files here, or click to select files</p>
                    <p>(Only *.jpeg, *.png, *.jpg, *.pdf formats are accepted)</p>
                </div>

                {files.length > 0 && (
                    <div className={classes.filePreview}>
                        <h4>Files:</h4>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index} className={classes.fileItem}>
                                    <span>{file.name}</span>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => removeFile(file)}
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className={classes.imageContainer}>
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={loading}
                        className={classes.submitButton}
                    >
                        {loading ? "Uploading..." : "Submit"}
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    );
};

export default UploadPodModal;
