import axios from "axios";
import {
  IAddUserPayload,
  IUpdateUserPayload,
  IUserChangeForgottenPasswordPayload,
  IUserChangePasswordPayload,
} from "../../../../Application/DTOs/UsersDto/UsersDto.type";
import { TOKEN_STORAGE_KEY } from "../../Types/Constants";
import { WebStorage } from "../../Utilities/WebStorage";

const baseUrl = process.env.REACT_APP_API_URL;

const storage = new WebStorage();

export class UsersApi {
  login(username: string, password: string) {
    return axios.post(`${baseUrl}/users/login`, { username, password });
  }

  verify(token: string) {
    return axios.post(
      `${baseUrl}/users/verify`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  add(formData: FormData, p0: { headers: { 'Content-Type': string; }; }) {

    return axios.post(`${baseUrl}/users/add`, formData, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  update(userId: string | number, data: IUpdateUserPayload) {
    return axios.post(`${baseUrl}/users/update/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  delete(userId: string | number) {
    return axios.post(
      `${baseUrl}/users/delete`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  rate(rating: number, token: string) {
    return axios.post(`${baseUrl}/users/rate`, { rating, token });
  }

  sendForgottenPasswordEmail(email: string) {
    return axios.post(`${baseUrl}/users/forgot-password/email`, { email });
  }

  changeForgottenPassword(payload: IUserChangeForgottenPasswordPayload) {
    return axios.post(`${baseUrl}/users/forgot-password/change`, payload);
  }

  getUsersWithRating(userTypes: string[], country?: string) {
    return axios.get(`${baseUrl}/users/rating`, {
      params: {
        userTypes,
        country,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  sendStatsEmail(email: string, country?: string) {
    return axios.post(
      `${baseUrl}/users/send/stats`,
      { email, country },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  changePassword(payload: IUserChangePasswordPayload) {
    return axios.post(`${baseUrl}/users/change-password`, payload, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  all(type: string[], country?: string) {
    return axios.get(`${baseUrl}/users/all`, {
      params: {
        type,
        country,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }




  companyGet(userId: string | number, data: IUpdateUserPayload) {
    return axios.post(`${baseUrl}/get/companyusers/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getDetails(userId: string | number) {
    return axios.get(`${baseUrl}/users/get`, {
      params: {
        userId,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getUserStats(country?: string) {
    return axios.get(`${baseUrl}/users/get/stats`, {
      params: {
        country,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}
