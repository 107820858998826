import { useTranslation } from "react-i18next";

export interface CompanyData {
    uuid: string;
    name: string;
    location: string;
}

export const companyData: CompanyData[] = [
    { uuid: '1', name: 'Company A', location: 'USA' },
    { uuid: '2', name: 'Company B', location: 'Canada' },
];

export const tripOptions = [
    { value: 'one_way', label: 'One-Way' },
    { value: 'return_trip', label: 'Return Trip' },
    { value: 'multi_stop', label: 'Multi Stop' },

];
export const packageTypeOptions = [
    { value: 'Pallets', label: 'Pallets' },
    { value: 'Cartons', label: 'Cartons' },
    { value: 'Pallecons', label: 'Pallecons' },
    { value: 'Loose', label: 'Loose' },
    { value: 'Other', label: 'Other' },
];


export const movementTypeOptions = [
    {id:"1",value: 'containers', label: 'Container' },
    {id:"2",value: 'loose_cargo', label: 'Loose Cargo' },
    {id:"3",value: 'full_truck_load', label: 'Full Truck Load' },
    {id:"4",value: 'oversized_items', label: 'Oversized Items' },
];

export const COUNTRIES = [
    {id:"1",value: 'australia', label: 'Australia' },
    {id:"2",value: 'new_zealand', label: 'New Zealand' },
];

export const AU_STATES = [
    {id:"1",value: 'WA', label: 'WA' },
    {id:"2",value: 'ACT', label: 'ACT' },
    {id:"3",value: 'VIC', label: 'VIC' },
    {id:"4",value: 'TAS', label: 'TAS' },
    {id:"5",value: 'NSW', label: 'NSW' },
    {id:"6",value: 'NT', label: 'NT' },
    {id:"7",value: 'QLD', label: 'QLD' },
    {id:"8",value: 'SA', label: 'SA' },
];
export const NZ_STATES = [
    {id:"1",value: 'AUCKLAND', label: 'AUCKLAND' },
    {id:"2",value: 'HAWKES_BAY', label: 'HAWKES BAY' },
    {id:"3",value: 'WELLINGTON', label: 'WELLINGTON' },
    {id:"4",value: 'TARANAKI', label: 'TARANAKI' },
    {id:"5",value: 'NELSON', label: 'NELSON' },
    {id:"6",value: 'CANTERBURY', label: 'CANTERBURY' },
    {id:"7",value: 'OTAGO', label: 'OTAGO' },
];

export const ADDRESS_GROUPS = [
    {id:"1",value: 'customer', label: 'Customer' },
    {id:"2",value: 'dc_or_warehouse', label: 'DC/Warehouse' },
    {id:"3",value: 'empty_park', label: 'Empty Park' },
    {id:"4",value: 'pack_or_unpack_depot', label: 'Pack/ Unpack Depot' },
    {id:"5",value: 'rail', label: 'Rail' },
    {id:"6",value: 'wharf', label: 'Wharf' },
    {id:"7",value: 'residential', label: 'Residental' },
];


export const DOCS = [
    { id: "2", value: 'EDO', label: 'EDO' },
    { id: "3", value: 'Cartage Advice', label: 'Cartage Advice' },
    { id: "4", value: 'Photo', label: 'Photo' },
    { id: "5", value: 'Photo damage', label: 'Photo damage' },
    { id: "6", value: 'Fumigation', label: 'Fumigation' },
    { id: "7", value: 'Tailgate', label: 'Tailgate' },
    { id: "8", value: 'Others', label: 'Others' },
    { id: "9", value: 'Pallet Labels', label: 'Pallet Labels' },
    { id: "10", value: 'Pick Slip', label: 'Pick Slip' },
    { id: "11", value: 'Delivery Note', label: 'Delivery Note' },
    { id: "12", value: 'Consignment Note', label: 'Consignment Note' },
    { id: "13", value: 'Pack list', label: 'Pack list' },
    { id: "14", value: 'Bill of Lading', label: 'Bill of Lading' },
    { id: "15", value: 'AQIS Unpack', label: 'AQIS Unpack' },
    { id: "16", value: 'AQIS Inspection', label: 'AQIS Inspection' },
    { id: "17", value: 'Tailgate Inspection', label: 'Tailgate Inspection' },
    { id: "18", value: 'Underbond', label: 'Underbond' },
    { id: "1", value: 'Delivery Docket', label: 'Delivery Docket' }
];

export const POST_TO = [
    { id: "1", value: 'public', label: 'PUBLIC' },
    { id: "2", value: 'associates', label: 'ASSOCIATES' },
    { id: "3", value: 'hidden', label: 'HIDDEN' },
    { id: "4", value: 'archive', label: 'ARCHIVE' }
];

export const users = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    { id: 3, name: 'Alice Johnson' }
  ];

export const PALLET_ACCOUNT_TYPE = [
    { id: "Cheps", value: 'Cheps', label: 'Cheps' },
    { id: "Loscams", value: 'Loscams', label: 'Loscams' },
    { id: "Plain", value: 'Plain', label: 'Plain' },
    { id: "Cheps Plastic", value: 'Cheps Plastic', label: 'Cheps Plastic' },
    { id: "Loscams Plastic", value: 'Loscams Plastic', label: 'Loscams Plastic' },
    { id: "Other", value: 'Other', label: 'Other' },
];

export const PALLET_ACCOUNT_ACTION = [
    { id: "transfer", value: 'transfer', label: 'Transfer' },
    { id: "exchange", value: 'exchange', label: 'Exchange' },
];

export const STATE_TYPE = [
    { id: "1", value: 'inner_metro', label: 'Inner Metro' },
    { id: "2", value: 'outer_metro', label: 'Outer Metro' },
];

export const USER_ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    COMPANY: 'COMPANY',
    ADMIN: 'ADMIN',
    DRIVER: 'DRIVER',
    COMPANY_USER: 'COMPANY_USER',
    ADMIN_USER: 'ADMIN_USER', // ADMIN FOR EACH COUNTRY
    COMPANY_DRIVER: 'COMPANY_DRIVER',
    DRIVER_MANAGER: 'DRIVER_MANAGER',
    OTHER: 'OTHER',
    CUSTOMER: 'CUSTOMER',
    WAREHOUSE: 'WAREHOUSE',
  };

  export const SERVICE_TYPE = [
    { id: "1", value: 'general', label: 'General' },
    { id: "2", value: 'express', label: 'Express' },
];
export const dummyRows = [
    { id: 1, name: 'John Doe', age: 30, email: 'john.doe@example.com', city: 'New York', gender: 'Male', occupation: 'Software Engineer', phone: '123-456-7890' },
    { id: 2, name: 'Jane Smith', age: 25, email: 'jane.smith@example.com', city: 'Los Angeles', gender: 'Female', occupation: 'Graphic Designer', phone: '234-567-8901' },
    { id: 3, name: 'Alice Johnson', age: 35, email: 'alice.johnson@example.com', city: 'Chicago', gender: 'Female', occupation: 'Project Manager', phone: '345-678-9012' },
    { id: 4, name: 'Bob Brown', age: 40, email: 'bob.brown@example.com', city: 'Houston', gender: 'Male', occupation: 'Data Scientist', phone: '456-789-0123' },
    { id: 5, name: 'Charlie Davis', age: 28, email: 'charlie.davis@example.com', city: 'Phoenix', gender: 'Male', occupation: 'Web Developer', phone: '567-890-1234' },
    { id: 6, name: 'Emily Clark', age: 32, email: 'emily.clark@example.com', city: 'Philadelphia', gender: 'Female', occupation: 'HR Specialist', phone: '678-901-2345' },
    { id: 7, name: 'David Wilson', age: 27, email: 'david.wilson@example.com', city: 'San Antonio', gender: 'Male', occupation: 'Product Owner', phone: '789-012-3456' },
    { id: 8, name: 'Sarah Martinez', age: 29, email: 'sarah.martinez@example.com', city: 'San Diego', gender: 'Female', occupation: 'Business Analyst', phone: '890-123-4567' },
    { id: 9, name: 'Michael Taylor', age: 45, email: 'michael.taylor@example.com', city: 'Dallas', gender: 'Male', occupation: 'Sales Manager', phone: '901-234-5678' },
    { id: 10, name: 'Jessica Lewis', age: 36, email: 'jessica.lewis@example.com', city: 'San Jose', gender: 'Female', occupation: 'Marketing Coordinator', phone: '012-345-6789' },
    { id: 11, name: 'James Thompson', age: 31, email: 'james.thompson@example.com', city: 'Austin', gender: 'Male', occupation: 'Software Tester', phone: '111-222-3333' },
    { id: 12, name: 'Laura Hall', age: 38, email: 'laura.hall@example.com', city: 'Jacksonville', gender: 'Female', occupation: 'Data Analyst', phone: '222-333-4444' },
    { id: 13, name: 'Chris White', age: 22, email: 'chris.white@example.com', city: 'San Francisco', gender: 'Male', occupation: 'Intern', phone: '333-444-5555' },
    { id: 14, name: 'Megan Harris', age: 34, email: 'megan.harris@example.com', city: 'Columbus', gender: 'Female', occupation: 'Content Writer', phone: '444-555-6666' },
    { id: 15, name: 'Brian Lewis', age: 26, email: 'brian.lewis@example.com', city: 'Fort Worth', gender: 'Male', occupation: 'Customer Support', phone: '555-666-7777' },
    { id: 16, name: 'Rachel Young', age: 29, email: 'rachel.young@example.com', city: 'Charlotte', gender: 'Female', occupation: 'SEO Specialist', phone: '666-777-8888' },
    { id: 17, name: 'Aaron King', age: 41, email: 'aaron.king@example.com', city: 'Indianapolis', gender: 'Male', occupation: 'Network Administrator', phone: '777-888-9999' },
    { id: 18, name: 'Sophia Wright', age: 24, email: 'sophia.wright@example.com', city: 'Seattle', gender: 'Female', occupation: 'UX Designer', phone: '888-999-0000' },
    { id: 19, name: 'Daniel Scott', age: 37, email: 'daniel.scott@example.com', city: 'Denver', gender: 'Male', occupation: 'Financial Analyst', phone: '999-000-1111' },
    { id: 20, name: 'Olivia Adams', age: 33, email: 'olivia.adams@example.com', city: 'Washington', gender: 'Female', occupation: 'Operations Manager', phone: '000-111-2222' },
  ];


  export const TEMPERATURE_TYPE = [
    { id: "1", value: 'Ambient', label: 'Ambient' },
    { id: "2", value: 'Chilled', label: 'Chilled' },
    { id: "3", value: 'Frozen', label: 'Frozen' },

];