import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, Grid, Button, TextField, Checkbox, FormControlLabel, InputAdornment } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IViewUserDetailsDialogProps } from "./ViewUserDetailsDialog.types";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IUpdateUserPayload, IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { COUNTRIES, STATUS, USER_PRIVILEGES, USER_ROLES } from "../../../Types/Constants";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { UsersApi } from "../../../Api/Users/Users";
import { toast } from "react-toastify";
import WarningDialog from "../WarningDialog/WarningDialog";
import { AppState } from "../../../Redux";

const {
    ACTIVE,
    INACTIVE
} = STATUS

const {
    CONFIGURATIONS,
    DRIVER_MANAGEMENT,
    TRANSPORT_MANAGEMENT,
    SEA_FREIGHT_MANAGEMENT
} = USER_PRIVILEGES

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    crossDivCss:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    mousePointerCss:{
        cursor: 'pointer'
    }
}))

const userApi = new UsersApi();
const { AUSTRALIA } = COUNTRIES;

const { ADMIN, ADMIN_RESTRICTED,ADMIN_OPERATIONS, ADMIN_USER, CARRIER, COMPANY,COMPANY_USER, SUPER_ADMIN, DRIVER_MANAGER, OTHER, CUSTOMER } =
  USER_ROLES;


function ViewUserDetailsDialog(props: IViewUserDetailsDialogProps) {
    const { open, handleClose, selectedUserId } = props
    const [userInfo, setUserInfo] = useState<IUserInfo | null>(null)
    const user = useSelector((state: AppState) => state.user);

    const isUserSuperAdmin = user.response?.type === SUPER_ADMIN;
    const isUserAdminUser = user.response?.type === ADMIN_USER;
    const isCustomer = user.response?.type === CUSTOMER;
    const isCompany = user.response?.type === COMPANY;
    const isCompanyUser = userInfo?.type === COMPANY_USER;
const isAdminrest = userInfo?.type === ADMIN_RESTRICTED;
const isAdminOper = userInfo?.type === ADMIN_OPERATIONS;
const isCarrier = userInfo?.type === CARRIER;

    // ADMIN_RESTRICTED:'ADMIN_RESTRICTED',
    // ADMIN_OPERATIONS: 'ADMIN_OPERATIONS',

    const renderUserDetails = (user, userInfo) => {
        const userType = user?.response?.type || userInfo?.type;
      
        switch (userType) {
          case CUSTOMER:
            return 'Customer Details'; 
          case COMPANY:
            return 'Company Details'; 
      
          case COMPANY_USER:
            return 'Company User Details'; 
         case ADMIN_RESTRICTED:
        return 'Admin Restricted Details';
        case ADMIN_OPERATIONS:
        return 'Admin Operations Details';
        case CARRIER:
        return 'Carrier Details';
        default:
            return 'Admin Details';
        }
      };
   
    // CUSTOMER: 'CUSTOMER',
    // WAREHOUSE: 'WAREHOUSE',
    // CARRIER: 'CARRIER',
    // ADMIN_RESTRICTED:'ADMIN_RESTRICTED',
    // ADMIN_OPERATIONS: 'ADMIN_OPERATIONS',
    const [t] = useTranslation('ViewUserDetailsDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [isTransportManagement, setIsTransportManagement] = useState(false)
    const [isConfiguration, setIsConfiguration] = useState(false)
    const [isSeaFreightManagement, setIsSeaFreightManagement] = useState(false)
    const [isDriverManagement, setIsDriverManagement] = useState(false)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)




    const [tabValue, setTabValue] = useState(0);
    const [company, setCompany] = useState('');
    const [Abn_Acn, setAbn_Acn] = useState('');
    const [job_title, setJob_Title] = useState('');
    const [phone, setPhone] = useState('');
    const [userPermission, setUserPermission] = useState('');
    const [address, setAddress] = useState('');
    const [website, setWebsite] = useState('');
    const [social_links, setSocial_Links] = useState('')
    const [privacy_settings, setPrivacy_Settings] = useState('')
    const [upload_docs, setUpload_Docs] = useState(null)
    const [communication_preferences, setCommunication_preferences] = useState('')
    const [country, setCountry] = useState<string | undefined>(
      isUserSuperAdmin ? AUSTRALIA : userInfo?.company?.country
    );







    const isDriverManager = userInfo?.type === DRIVER_MANAGER

    const handleWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )

    const setUserDetailsToState = useCallback(
        (user?: IUserInfo) => {

            setFirstName(user?.firstName)
            setLastName(user?.lastName)
            setEmail(user?.email)
            setMobileNumber(user?.mobile)
            setIsTransportManagement(user?.privileges?.includes(TRANSPORT_MANAGEMENT))
            setIsConfiguration(user?.privileges?.includes(CONFIGURATIONS))
            setIsSeaFreightManagement(user?.privileges?.includes(SEA_FREIGHT_MANAGEMENT))
            setIsDriverManagement(user?.privileges?.includes(DRIVER_MANAGEMENT))


            // setCompany(user.company)
            setAbn_Acn(user?.abn_acn);
            setJob_Title(user?.jobtitle);
            setPhone(user?.mobile);
            setUserPermission(user?.type);
            setAddress(user?.address);
            setWebsite(user?.website);
            setSocial_Links(user?.social_links);
            setPrivacy_Settings(user?.privacy_settings);
            setUpload_Docs(user?.upload_docs);
            setCommunication_preferences(user?.communication_preferences?.replace(/^"|"$/g, ''));
            setCountry(user?.country);
        },
        [],
    )

    const getUserDetails = useCallback(
        (selectedUserId: string | number) => {
            dispatch(setLoader(true))
            userApi.getDetails(selectedUserId)
                .then((response) => {
                    const user: any = response.data
                    setUserInfo(user)
                    setUserDetailsToState(user)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    handleClose()
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_user_detail'))
                })
        },
        [dispatch, t, setUserDetailsToState, handleClose],
    )

    useEffect(() => {
        if (selectedUserId) {
            getUserDetails(selectedUserId)
        }
    }, [selectedUserId, getUserDetails])

    const handleOnEditClick = useCallback(
        () => {
            setIsEditMode(true)
        },
        [],
    )

    const handleOnCancelClick = useCallback(
        () => {
            setIsEditMode(false)
            if (userInfo)
                setUserDetailsToState(userInfo)
        },
        [setUserDetailsToState, userInfo],
    )

    const handleOnUpdateClick = useCallback(
        () => {
            //TODO: ADD VALIDATIONS

            if (selectedUserId) {
                dispatch(setLoader(true))

                const privileges: string[] = []

                if (isConfiguration) privileges.push(CONFIGURATIONS)
                if (isTransportManagement) privileges.push(TRANSPORT_MANAGEMENT)
                if (isSeaFreightManagement) privileges.push(SEA_FREIGHT_MANAGEMENT)
                if (isDriverManagement) privileges.push(DRIVER_MANAGEMENT)
                let payload: IUpdateUserPayload = {
                    email,
                    firstName,
                    lastName,
                    mobile: mobileNumber,
                    privileges,
                    status: userInfo?.status,
                    type: userInfo?.type,
                    abn_acn: Abn_Acn,
                    job_title: job_title,
                    address: address,
                 website,
                    social_links: social_links,
                    communication_preferences: communication_preferences,
                    privacy_settings: privacy_settings,
                    user_role: userPermission,
                    // upload_docs: upload_docs
                }

                userApi.update(selectedUserId, payload)
                    .then(() => {
                        dispatch(setLoader(false))
                        setIsEditMode(false)
                        if (selectedUserId)
                            getUserDetails(selectedUserId)
                    })
                    .catch((err: any) => {
                        dispatch(setLoader(false))
                        toast.error(t('error_updating_user_detail'))
                    })
            }
        },
        [selectedUserId, dispatch, isConfiguration, isTransportManagement, isSeaFreightManagement, isDriverManagement, userPermission, Abn_Acn, job_title, social_links, privacy_settings, email, firstName, lastName, mobileNumber, userInfo?.status, userInfo?.type, address, website, communication_preferences, getUserDetails, t],
    )

    const handleUpdateStatusClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))

                userApi.update(selectedUserId, { status: userInfo?.status === INACTIVE ? ACTIVE : INACTIVE })
                    .then(() => {
                        dispatch(setLoader(false))
                        setIsEditMode(false)
                        if (selectedUserId)
                            getUserDetails(selectedUserId)
                    })
                    .catch((err: any) => {
                        dispatch(setLoader(false))
                        toast.error(t('error_updating_user_detail'))
                    })
            }
        },
        [userInfo?.status, dispatch, t, selectedUserId, getUserDetails],
    )

    const handleOnProceedDeleteClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                userApi.delete(selectedUserId)
                    .then(() => {
                        toast.success(t('success_deleting_user'))
                        handleWarningDialogClose()
                        handleClose()
                        dispatch(setLoader(false))
                    })
                    .catch(err => {
                        toast.error(t('error_deleting_user'))
                        dispatch(setLoader(false))
                    })
            }
        },
        [dispatch, t, selectedUserId, handleWarningDialogClose, handleClose],
    )

    const handleOnDeleteClick = useCallback(
        () => {
            setIsWarningDialogOpen(true)
        },
        [],
    )


    // const isCustomer = user.response?.type === CUSTOMER;
    // const isCompany = user.response?.type === COMPANY;
    // const isCompanyUser = userInfo?.type === COMPANY_USER;


    return (
        <>
            <WarningDialog message={t('warning_about_to_delete_user')} open={isWarningDialogOpen} handleClose={handleWarningDialogClose} onProceedClick={handleOnProceedDeleteClick} />
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <div className={classes.crossDivCss} >
                    <Typography>
                        {/* {t('user_details')} */}

                        {renderUserDetails(user, userInfo)}
                    </Typography>
                    <div><ClearIcon className={classes.mousePointerCss}  onClick={handleClose} /></div>
                    </div>
                    

                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEditMode}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_first_name')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     )
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEditMode}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_last_name')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     )
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                disabled={true}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_email_address')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <AlternateEmailIcon />
                                //         </InputAdornment>
                                //     )
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>


                     

                        <Grid md={6} item>
                            <TextField
                                  value={website}
                                  disabled={!isEditMode}
                                // onChange={(e) => setWebsite(e.target.value)}
                                  onChange={(e) => { if (e.target.value.length <= 50) { setWebsite(e.target.value) } }}
                                variant="outlined"
                                fullWidth
                                label={t('Website')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>


                        <Grid item md={4} xs={12}>
                            <TextField
                                value={company}
                                disabled={!isEditMode}
                                // onChange={(e) => setCompany(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 25) { setCompany(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t('company')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                value={Abn_Acn}
                                disabled={!isEditMode}
                                // onChange={(e) => setAbn_Acn(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 11) { setAbn_Acn(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t('ABN/ACN')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <TextField
                                value={phone}
                                disabled={!isEditMode}
                                // onChange={(e) => setPhone(e.target.value)}
                                // onChange={(e) =>{ if(e.target.value.length <= 10 && e.target.value !< 0){setPhone(e.target.value)}}}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d{0,10}$/.test(value)) {
                                        setPhone(value);
                                    }
                                }}
                                type="number"
                                variant="outlined"
                                fullWidth
                                label={t('Phone')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                value={address}
                                disabled={!isEditMode}
                                // onChange={(e) => setAddress(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 30) { setAddress(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t('Address')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>


                        <Grid item md={4} xs={12}>
                            <TextField
                                value={job_title}
                                disabled={!isEditMode}
                                // onChange={(e) => setJob_Title(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 25) { setJob_Title(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t("Job Title")}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                value={communication_preferences}
                                disabled={!isEditMode}
                                // onChange={(e) => setCommunication_preferences(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 25) { setCommunication_preferences(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t("Communication Preferences")}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                value={privacy_settings}
                                disabled={!isEditMode}
                                // onChange={(e) => setPrivacy_Settings(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 25) { setPrivacy_Settings(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t("Privacy Settings")}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                value={social_links}
                                disabled={!isEditMode}
                                // onChange={(e) => setSocial_Links(e.target.value)}
                                onChange={(e) => { if (e.target.value.length <= 50) { setSocial_Links(e.target.value) } }}

                                variant="outlined"
                                fullWidth
                                label={t('Social Media Links')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <TextFieldsIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        {/* <Grid md={6} item>
                            <TextField
                                // inputRef={fileInputRef}
                                type="file"
                                // onChange={handleImageChange}
                                variant="outlined"
                                fullWidth
                                // label={t('Upload_docs')}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid> */}
                {isUserSuperAdmin &&  isUserAdminUser &&      <>
                            <Grid md={12} item>
                                <Typography align="left" variant="body1">
                                    {t('User Permission')}
                                </Typography>
                            </Grid>

                
                            <Grid md={3} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        disabled={!isEditMode}
                                            color="primary"
                                            checked={userPermission == 'ADMIN_RESTRICTED'}
                                            onChange={(e) => {
                                                setUserPermission(e.target.checked ? 'ADMIN_RESTRICTED' : null);
                                            }
                                            }
                                        />
                                    }
                                    label={t('Restricted')}
                                />
                            </Grid>

                            <Grid md={3} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        disabled={!isEditMode}
                                            color="primary"
                                            checked={userPermission == 'ADMIN_OPERATIONS'}
                                            onChange={(e) => {
                                                console.log('userPermission11', e.target.checked);
                                                setUserPermission(e.target.checked ? 'ADMIN_OPERATIONS' : null);
                                            }}
                                        />
                                    }
                                    label={t('Operations')}
                                />
                            </Grid>
                        </>
}


{/* {isUserSuperAdmin || userType === OTHER ? ( */}
                        {
                            !isDriverManager && isUserSuperAdmin || isUserAdminUser
                                ? <>
                                    <Grid md={12} item>
                                        <Typography align="left" variant="body1">
                                            {t('current_privileges')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isTransportManagement} onChange={(e) => setIsTransportManagement(e.target.checked)} />}
                                            label={t('transport_management')}
                                        />
                                    </Grid>
                                    {/* <Grid md={9} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isSeaFreightManagement} onChange={(e) => setIsSeaFreightManagement(e.target.checked)} />}
                                            label={t('sea_freight_management')}
                                        />
                                    </Grid> */}
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isConfiguration} onChange={(e) => setIsConfiguration(e.target.checked)} />}
                                            label={t('configuration')}
                                        />
                                    </Grid><Grid md={9} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isDriverManagement} onChange={(e) => setIsDriverManagement(e.target.checked)} />}
                                            label={t('driver_management')}
                                        />
                                    </Grid>
                                </>
                                : null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item md={6}>
                            {
                                !isEditMode
                                    ? <Grid item md={3}>
                                        <Button onClick={handleOnDeleteClick} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                            {t('delete')}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </Grid>

                        <Grid item md={6}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                {
                                    !isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleUpdateStatusClick} className={classes.updatebtn} startIcon={userInfo?.status === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(userInfo?.status === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    !isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnEditClick} startIcon={<EditIcon />} fullWidth className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnUpdateClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnCancelClick} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>

                        
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewUserDetailsDialog